/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Operation } from "@/model/api/Operation";
import Pilot from "@/model/api/Pilot";
import Platform from "@/model/api/Platform";
import Tracker from "@/model/api/Tracker";
import { FaChartBar } from "react-icons/fa";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdHelp,
} from "react-icons/io";
import { RxTriangleDown } from "react-icons/rx";
import { RoundedDatePicker } from "@/components/v1/Common/RoundedDatePicker";
import UserAccess from "@/model/UserAccess";
import { IoEllipsisHorizontal } from "react-icons/io5";
import moment from "moment";
import InputMask from "react-input-mask";
import FrameRenderer from "./FrameRenderer";
import Button from "../../Common/Button";
import Loader from "../../Common/Loader";
import PilotItem from "../AssetManagement/ItemCards/PilotItem";
import ItemOperation from "../FlightAuthorization/ItemCards/ItemOperation";
import PilotStatistic from "./Statistic/PilotStatistic";
import {
  downloadCSV,
  downloadMultipleJSON,
  downloadSingleJSON,
  telemetryToCsv,
} from "../../../../services/json";
import PlatformItem from "../AssetManagement/ItemCards/PlatformItem";
import Platformstatistic from "./Statistic/PlatformStatistic";

interface FlightLogsProps {
  api: any;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
  handleRepeatOperation: (operationId: string) => void;
}

interface UserAccessState {
  userAccess: UserAccess;
}

interface FilterModalProps {
  handleApplyFilter: (filterStart: string, filterEnd: string) => void;
}
function FilterModal({ handleApplyFilter }: FilterModalProps) {
  const [form, setForm] = useState({ filterStart: "", filterEnd: "" });
  const [formError, setFormError] = useState({
    filterStart: "",
    filterEnd: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const error = {
      filterStart: "",
      filterEnd: "",
    };

    try {
      if (form.filterStart !== "") {
        const date = moment(form.filterStart, "DD/MM/YYYY", true);

        if (!date.isValid()) {
          throw new Error("Invalid date");
        }
      }
    } catch {
      error.filterStart = "Invalid date";
      isValid = false;
    }
    try {
      if (form.filterEnd !== "") {
        const date = moment(form.filterEnd, "DD/MM/YYYY", true);

        if (!date.isValid()) {
          throw new Error("Invalid date");
        }
      }
    } catch {
      error.filterEnd = "Invalid date";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleApplyFilterClick = () => {
    if (!validateForm()) {
      return;
    }
    handleApplyFilter(form.filterStart, form.filterEnd);
  };
  return (
    <div className="flex-col ml-5 mr-5" onClick={(e) => e.stopPropagation()}>
      <div className="flex py-2 items-center content-center">
        <div className="flex flex-col">
          <p>From :</p>
          <InputMask
            id="filterStart"
            mask="99/99/9999"
            maskChar={null}
            placeholder="dd/mm/yyyy"
            className="input-text"
            onChange={onChange}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          />
          {formError.filterStart && (
            <p className="text-red-500 text-sm mt-1">{formError.filterStart}</p>
          )}
        </div>
        <span className="m-5" />
        <div className="flex flex-col">
          <p>To :</p>
          <InputMask
            id="filterEnd"
            mask="99/99/9999"
            maskChar={null}
            placeholder="dd/mm/yyyy"
            className="input-text"
            onChange={onChange}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          />
          {formError.filterEnd && (
            <p className="text-red-500 text-sm mt-1">{formError.filterEnd}</p>
          )}
        </div>
      </div>

      <Button
        type="primaryDark"
        size="medium"
        text="Apply Filter"
        className="mr-2 mt-5 bottom-0"
        onClick={handleApplyFilterClick}
      />
    </div>
  );
}

export default function FlightLogs({
  api,
  showMessage,
  onOpenModal,
  handleRepeatOperation,
  onCloseModal,
}: FlightLogsProps) {
  const [options, setOptions] = useState<string[]>(["All Operation Logs"]);
  const [selectedOption, setSelectedOption] = useState("All Operation Logs");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showOperationList, setShowOperationList] = useState(false);
  const [operationsTitle, setOperationsTitle] = useState("Flight Logs");

  /* More Options */
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  /* Replay Dialog */
  const [selectedFlights, setSelectedFlights] = useState<Operation[]>([]);
  const [openReplayDialog, setOpenReplayDialog] = useState(false);

  /* API State */
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /* Operation Data */
  const [operations, setOperations] = useState<Operation[]>([]);
  const [ridOperations, setRidOperations] = useState<Operation[]>([]);

  /* Assets */
  const [pilots, setPilots] = useState<Pilot[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [trackers, setTrackers] = useState<Tracker[]>([]);

  const [selectedPilotPlatformUuid, setSelectedPilotPlatformUuid] =
    useState<string>("");

  /* Filter */
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState<string>("");
  const [filterEndDate, setFilterEndDate] = useState<string>("");
  const [filteredOperations, setFilteredOperations] = useState<Operation[]>([]);

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  useEffect(() => {
    const optionTemp = ["All Operation Logs"];
    if (!userAccess) return;
    if (userAccess.privileges.includes("assets.pilot.read"))
      optionTemp.push("Pilot");
    if (userAccess.privileges.includes("assets.platform.read"))
      optionTemp.push("Platforms");
    if (userAccess.privileges.includes("operations.rid.read"))
      optionTemp.push("Rid Operations");
    optionTemp.push("Replay Logs");

    setOptions(optionTemp);
  }, [userAccess]);

  const handleSelectedOption = (option: string) => {
    setSelectedIds([]);
    setOperations([]);
    setSelectedOption(option);
    setIsDropdownOpen(false);
    setIsFilterEnabled(false);
    if (!(option === "Pilot" || option === "Platforms")) {
      fetchOperations();
    }
  };

  const handleCloseDialog = () => {
    setOpenReplayDialog(false);
  };

  const handleOperationClicked = (operation: Operation) => {
    const content = (
      <div className="flex-col" onClick={(e) => e.stopPropagation()}>
        <table className="table-auto w-full">
          <tbody>
            <tr className="border rounded-t-md">
              <td className="px-4 py-2">Operation</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.reference.description}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Departure</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_start.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Arrival</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_end.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Pilot</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getPilotName(operation.operation_json.request.pilot_uuid[0])}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Platform</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getPlatformName(
                  operation.operation_json.request.platform_uuid[0]
                )}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Tracker</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getTrackerName(
                  operation.operation_json.request.tracker_uuid[0]
                )}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Status</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.details.state}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Operation ID</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.reference.id}
              </td>
            </tr>
            <tr className="border rounded-b-md">
              <td className="px-4 py-2">Created On</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.reference.time_created.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    onOpenModal("Operation Detail", content);
  };

  const handleItemClicked = (data: any) => {
    setShowOperationList(true);
    if (selectedOption === "Pilot") {
      setOperationsTitle(`Pilot ${data.pilot_username} Operations`);
      fetchPilotOperations(data.pilot_uuid);
      setSelectedPilotPlatformUuid(data.pilot_uuid);
    } else if (selectedOption === "Platforms") {
      setOperationsTitle(`Platform ${data.platform_callsign} Operations`);
      fetchPlatformOperations(data.platform_uuid);
      setSelectedPilotPlatformUuid(data.platform_uuid);
    }
  };

  const handleItemSelected = (id: string) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleExportJSON = async () => {
    setShowMoreOptions(false);

    if (selectedIds.length === 0) {
      showMessage && showMessage("Select an item to export");
      return;
    }
    showMessage && showMessage("Exporting JSON, please wait");

    const operations = (
      await Promise.all(
        selectedIds.map((op) => {
          return api.getOperationData({
            operationID: op,
          });
        })
      )
    ).map((response) => response.data);
    if (operations.length === 1) {
      await downloadSingleJSON(
        operations[0],
        operations[0].reference.description
      );
      return;
    }
    await downloadMultipleJSON(
      operations.map((o) => {
        return {
          name: o.operation_json.reference.description,
          data: o,
        };
      }),
      "flight-logs"
    );
  };

  const handleExportCSV = () => {
    setShowMoreOptions(false);
    if (selectedIds.length === 0) {
      showMessage && showMessage("Select an item to export");
      return;
    }
    let selectedOperations;
    if (selectedOption === "Rid Operations") {
      selectedOperations = ridOperations.filter((op) =>
        selectedIds.includes(op.operation_json.reference.id)
      );
    } else {
      selectedOperations = operations.filter((op) =>
        selectedIds.includes(op.operation_json.reference.id)
      );
    }

    downloadCSV(selectedOperations, "flight-logs");
  };

  const handleExportReplayFlight = async () => {
    showMessage && showMessage("Retrieving telemetry");
    setShowMoreOptions(false);
    if (selectedIds.length === 0) {
      showMessage && showMessage("Select an item to export");
      return;
    }
    let selectedOperations;
    if (selectedOption === "Rid Operations") {
      selectedOperations = ridOperations.filter((op) =>
        selectedIds.includes(op.operation_json.reference.id)
      );
    } else {
      selectedOperations = operations.filter((op) =>
        selectedIds.includes(op.operation_json.reference.id)
      );
    }
    selectedOperations.forEach(async (singleOperation) => {
      try {
        const operationTelemetry = await api.getOperationTelemetry(
          singleOperation.operation_json.reference.id
        );

        // downloadCSV(operationTelemetry, "flight-logs");
        operationTelemetry.data.forEach((singleTrackerData: any) => {
          if (!singleTrackerData.telemetry.length) {
            showMessage &&
              showMessage(`No telemetry for ${singleTrackerData.tracker_sn}`);
            return;
          }
          telemetryToCsv(singleTrackerData);
        });
      } catch {
        showMessage &&
          showMessage(
            `Unable to retrieve telemetry for ${singleOperation.operation_json.reference.id}`,
            false,
            true
          );
      }
    });
  };

  const handleReplayOperation = () => {
    setShowMoreOptions(false);
    if (selectedIds.length !== 1) {
      showMessage && showMessage("Select single operation", false, true);
      return;
    }

    const selectedOperations = operations.filter((op) =>
      selectedIds.includes(op.operation_json.reference.id)
    );
    setSelectedFlights(selectedOperations);

    const content = (
      <>
        <FrameRenderer
          operationUuid={selectedOperations[0].operation_json.reference.id}
          showMessage={showMessage}
          onCloseModal={onCloseModal}
        />
      </>
    );
    onOpenModal("Replay Operation", content, false, true);
  };

  const fetchPilotOperations = async (
    pilotId: string,
    getOlderLogs = false
  ) => {
    let currentOperations: Operation[] = [];
    if (getOlderLogs) {
      currentOperations = operations;
    } else {
      setOperations([]);
    }
    setIsLoading(true);
    try {
      const response = await api.getPilotOperations(
        pilotId,
        currentOperations.length
      );
      if (response.error) {
        setIsError(true);
        setErrorMessage("Unable to Retrive Operations Data");
      } else {
        setOperations([...currentOperations, ...response.data]);
      }
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setErrorMessage("Unable to Retrive Operations Data");
    }
  };

  const fetchPlatformOperations = async (
    platformId: string,
    getOlderLogs = false
  ) => {
    let currentOperations: Operation[] = [];
    if (getOlderLogs) {
      currentOperations = operations;
    } else {
      setOperations([]);
    }
    setIsLoading(true);
    try {
      const response = await api.getPlatformOperations(
        platformId,
        currentOperations.length
      );
      if (response.error) {
        setIsError(true);
        setErrorMessage("Unable to Retrive Operations Data");
      } else {
        setOperations([...currentOperations, ...response.data]);
      }
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setErrorMessage("Unable to Retrive Operations Data");
    }
  };

  const fetchPilots = async () => {
    if (!userAccess.privileges.includes("assets.pilot.read")) return [];
    const response = await api.getPilots();
    if (response.error) {
      showMessage && showMessage("Unable to Retrive Pilots Data", false, true);
      setIsError(true);
    } else {
      setPilots(response.data);
    }
  };

  const fetchPlatforms = async () => {
    if (!userAccess.privileges.includes("assets.platform.read")) return [];
    const response = await api.getPlatforms();
    if (response.error) {
      showMessage &&
        showMessage("Unable to Retrive Platforms Data", false, true);
    } else {
      setPlatforms(response.data);
    }
  };

  const fetchTrackers = async () => {
    if (!userAccess.privileges.includes("assets.tracker.read")) return [];
    const response = await api.getTracker();
    if (response.error) {
      showMessage &&
        showMessage("Unable to Retrive Trackers Data", false, true);
    } else {
      setTrackers(response.data);
    }
  };

  const copyOperationId = (operationId: string) => {
    navigator.clipboard.writeText(operationId);
    showMessage && showMessage("Operation ID Copied", true);
  };

  const getPilotName = (pilotId: string) => {
    const pilot = pilots.find((p) => p.pilot_uuid === pilotId);
    return pilot ? pilot.pilot_username : "Unknown Pilot";
  };

  const getPlatformName = (platformId: string) => {
    const platform = platforms.find((p) => p.platform_uuid === platformId);
    return platform ? platform.platform_callsign : "Unknown Platform";
  };

  const getTrackerName = (trackerId: string) => {
    const tracker = trackers.find((t) => t.tracker_uuid === trackerId);
    return tracker ? tracker.tracker_name : "Unknown Tracker";
  };

  const durationCalculator = (dateTakeOff: any, dateLanding: any) => {
    try {
      if (dateTakeOff === 0 || dateLanding === 0) {
        return "NA";
      }
      const dateTakeOffInt = parseInt(dateTakeOff, 10);
      const dateLandingInt = parseInt(dateLanding, 10);

      const a = new Date(dateTakeOffInt);
      const b = new Date(dateLandingInt);

      const c = b.getTime() - a.getTime();

      const d = [
        Math.floor(c / (1000 * 60 * 60))
          .toString()
          .padStart(2, "0"),
        Math.floor((c / (1000 * 60)) % 60)
          .toString()
          .padStart(2, "0"),
        Math.floor((c / 1000) % 60)
          .toString()
          .padStart(2, "0"),
      ];

      return d.join(":");
    } catch (err) {
      return "NA";
    }
  };

  const fetchOperations = async () => {
    setIsLoading(true);
    try {
      const currentOperations = operations;
      const currentRidOperations = ridOperations;
      const [response, logState, ridOperationsRes] = await Promise.all([
        api.getOperations({
          isPast: true,
          offset: currentOperations.length,
          isOperations: true,
        }),
        api.getLogState(),
        api.getOperations({
          isPast: true,
          offset: currentRidOperations.length,
          isRidOperations: true,
        }),
      ]);
      const logStateGrouped = logState.data.reduce((acc: any, log: any) => {
        const { operation_uuid } = log;
        acc[operation_uuid] = acc[operation_uuid] ?? [];
        if (log.operation_state === "Activated") {
          acc[operation_uuid].takeoff_time = log.event_time;
        } else if (log.operation_state === "Ended") {
          acc[operation_uuid].landing_time = log.event_time;
        }
        acc[operation_uuid].takeoff_time ??= 0;
        acc[operation_uuid].landing_time ??= 0;
        return acc;
      }, {});
      if (response.error) {
        setIsError(true);
        setErrorMessage("Unable to Retrive Operations Data");
      } else {
        const updateOperationWithTakeoffLandingTime = response.data.map(
          (singleResponse: any) => {
            const modifiedOperationData = {
              operation_json: {
                ...singleResponse.operation_json,
                details: {
                  ...singleResponse.operation_json.details,
                  takeoff_time: logStateGrouped[
                    singleResponse.operation_json.reference.id
                  ]?.takeoff_time
                    ? logStateGrouped[
                        singleResponse.operation_json.reference.id
                      ].takeoff_time
                    : 0,
                  landing_time: logStateGrouped[
                    singleResponse.operation_json.reference.id
                  ]?.landing_time
                    ? logStateGrouped[
                        singleResponse.operation_json.reference.id
                      ].landing_time
                    : 0,
                },
              },
            };
            if (
              modifiedOperationData.operation_json.details.takeoff_time &&
              modifiedOperationData.operation_json.details.landing_time
            ) {
              const flightDuration = durationCalculator(
                modifiedOperationData.operation_json.details.takeoff_time,
                modifiedOperationData.operation_json.details.landing_time
              );
              modifiedOperationData.operation_json.details.flight_duration =
                flightDuration;
            }
            return modifiedOperationData;
          }
        );

        const updateRidOperationWithTakeoffLandingTime =
          ridOperationsRes.data.map((singleResponse: any) => {
            const modifiedOperationData = {
              operation_json: {
                ...singleResponse.operation_json,
                details: {
                  ...singleResponse.operation_json.details,
                  takeoff_time: logStateGrouped[
                    singleResponse.operation_json.reference.id
                  ]?.takeoff_time
                    ? logStateGrouped[
                        singleResponse.operation_json.reference.id
                      ].takeoff_time
                    : 0,
                  landing_time: logStateGrouped[
                    singleResponse.operation_json.reference.id
                  ]?.landing_time
                    ? logStateGrouped[
                        singleResponse.operation_json.reference.id
                      ].landing_time
                    : 0,
                },
              },
            };
            if (
              modifiedOperationData.operation_json.details.takeoff_time &&
              modifiedOperationData.operation_json.details.landing_time
            ) {
              const flightDuration = durationCalculator(
                modifiedOperationData.operation_json.details.takeoff_time,
                modifiedOperationData.operation_json.details.landing_time
              );
              modifiedOperationData.operation_json.details.flight_duration =
                flightDuration;
            }
            return modifiedOperationData;
          });

        const updatedOperations = updateOperationWithTakeoffLandingTime.sort(
          (a: Operation, b: Operation) => {
            const timeStartA = new Date(
              a.operation_json.interuss.operational_intent_reference.time_start.value
            );
            const timeStartB = new Date(
              b.operation_json.interuss.operational_intent_reference.time_start.value
            );
            return timeStartB.getTime() - timeStartA.getTime();
          }
        );
        setOperations([...currentOperations, ...updatedOperations]);

        const updatedRidOperations =
          updateRidOperationWithTakeoffLandingTime.sort(
            (a: Operation, b: Operation) => {
              const timeStartA = new Date(
                a.operation_json.interuss.operational_intent_reference.time_start.value
              );
              const timeStartB = new Date(
                b.operation_json.interuss.operational_intent_reference.time_start.value
              );
              return timeStartB.getTime() - timeStartA.getTime();
            }
          );
        setRidOperations(updatedRidOperations);
      }
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setErrorMessage("Unable to Retrive Operations Data");
    }
  };

  const openStatisticModal = () => {
    if (selectedIds.length === 0) {
      showMessage && showMessage("Select an item to view statistic");
      return;
    }
    if (selectedIds.length > 1) {
      showMessage && showMessage("Select only one item to view statistic");
      return;
    }

    if (selectedOption === "Pilot") {
      const pilot = pilots.find((p) => p.pilot_uuid === selectedIds[0]);
      if (pilot) {
        const content = (
          <>
            <PilotStatistic api={api} pilotData={pilot} />
          </>
        );
        onOpenModal("Pilot Statistic", content, false, false);
      }
    } else if (selectedOption === "Platforms") {
      const platform = platforms.find(
        (p) => p.platform_uuid === selectedIds[0]
      );
      if (platform) {
        const content = (
          <>
            <Platformstatistic api={api} platformData={platform} />
          </>
        );
        onOpenModal("Platform Statistic", content, false, false);
      }
    }
  };

  /* Repeat Operation */
  const handleRepeatButtonClick = () => {
    // dispatch(
    //   setOperationFormRequest({
    //     id: data.operation_json.reference.id,
    //     type: "duplicate",
    //     request: data.request_json,
    //   })
    // );
    handleRepeatOperation(selectedIds[0]);
  };

  useEffect(() => {
    if (api) {
      setIsLoading(true);
      fetchPilots().then(() => fetchOperations());
      fetchPlatforms();
      fetchTrackers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  const handleGetOlderLogsClick = () => {
    if (selectedOption === "Pilot") {
      fetchPilotOperations(selectedPilotPlatformUuid, true);
    } else if (selectedOption === "Platforms") {
      fetchPlatformOperations(selectedPilotPlatformUuid, true);
    } else {
      fetchOperations();
    }
  };

  useEffect(() => {
    if (!isFilterEnabled) return;
    filterOperations();
  }, [isFilterEnabled]);

  const filterOperations = () => {
    if (!operations) return [];
    try {
      let operationListFiltered =
        selectedOption === "Rid Operations"
          ? [...ridOperations]
          : [...operations];
      if (filterStartDate || filterEndDate) {
        const startDate = filterStartDate
          ? moment(filterStartDate, "DD/MM/YYYY")
          : null;
        const endDate = filterEndDate
          ? moment(filterEndDate, "DD/MM/YYYY")
          : null;
        operationListFiltered = operationListFiltered.filter(
          (singleOperation) => {
            const operationStartDate = moment(
              singleOperation.operation_json.reference.time_start.value.split(
                "T"
              )[0]
            );
            if (startDate && endDate) {
              return (
                operationStartDate.isSameOrAfter(startDate) &&
                operationStartDate.isSameOrBefore(endDate)
              );
            }
            if (startDate) return operationStartDate.isSameOrAfter(startDate);
            return operationStartDate.isSameOrBefore(endDate);
          }
        );
      }

      setFilteredOperations(operationListFiltered);
    } catch {
      // handle invalid dates that bypass validation check
      setFilteredOperations(operations);
    }
  };

  const handleClearFilter = () => {
    setFilterStartDate("");
    setFilterEndDate("");
    setIsFilterEnabled(false);
  };

  const handleFilterClick = () => {
    const handleApplyFilter = (filterStart: string, filterEnd: string) => {
      if (filterStart) setFilterStartDate(filterStart);
      if (filterEnd) setFilterEndDate(filterEnd);
      // isFilterEnabled triggers useeffect
      if (filterStart || filterEnd) setIsFilterEnabled(true);
      onCloseModal();
    };
    const content = <FilterModal handleApplyFilter={handleApplyFilter} />;

    onOpenModal("Filter Operations", content);
  };

  return (
    <div className="absolute top-0 bottom-0 w-full">
      {/* Data List */}
      <div className="flex-col h-[90%] overflow-auto">
        <div className="h-[90%] flex-col">
          {/* Header */}

          {showOperationList ? (
            <div className="flex-col">
              <div className="flex py-2 items-center content-center">
                <button
                  onClick={() => setShowOperationList(false)}
                  className="p-2"
                >
                  <IoIosArrowBack size={24} className="m-auto" />
                </button>
                <h5 className="font-medium ml-2">{operationsTitle}</h5>
                <span className="grow" />
                <button onClick={() => handleGetOlderLogsClick()}>
                  <span className="text-primary-600 text-sm font-medium mr-2">
                    Get Older Logs
                  </span>
                </button>
              </div>
              <hr />
            </div>
          ) : (
            <div className="flex-col">
              <div className="flex p-4 h-[8%]">
                <h4>Flight Logs</h4>
                <span className="grow" />
                <div className="w-6 h-6 bg-blue-200 rounded-lg flex">
                  <IoMdHelp className="fill-blue-500 m-auto" size={16} />
                </div>
              </div>
              <hr />
              <div className="flex-col">
                <div className="flex px-4 py-2 mt-2">
                  <h5 className="font-medium ml-2">Select Log Type</h5>
                  <button
                    onClick={() =>
                      isFilterEnabled
                        ? handleClearFilter()
                        : handleFilterClick()
                    }
                  >
                    <div className="w-6 h-6 rounded-lg flex ml-1">
                      <RxTriangleDown
                        className={`${
                          isFilterEnabled ? "text-red-500" : "text-gray-500"
                        } m-auto`}
                        size={16}
                      />
                    </div>
                  </button>
                  <span className="grow" />
                  <button onClick={() => handleGetOlderLogsClick()}>
                    <span className="text-primary-600 text-sm font-medium">
                      Get Older Logs
                    </span>
                  </button>
                </div>

                {/* Dropdown Option Picker */}
                <div className="relative px-4">
                  <button
                    className="input-select"
                    type="button"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <span className="grow">{selectedOption}</span>
                    {isDropdownOpen ? (
                      <IoIosArrowUp size={18} />
                    ) : (
                      <IoIosArrowDown size={18} />
                    )}
                  </button>

                  {isDropdownOpen && (
                    <div className="absolute top-14 w-[92%] z-[150]">
                      <ul className="dropdown-ul">
                        {options.map((option) => (
                          <li
                            key={option}
                            className="dropdown-li"
                            onClick={() => handleSelectedOption(option)}
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Content */}
          <div
            className={`${
              showOperationList ? "h-[90%]" : "h-[80%]"
            } flex-col overflow-auto p-4`}
          >
            {/* Data Logs */}
            {(selectedOption === "All Operation Logs" || showOperationList) &&
              (isFilterEnabled ? filteredOperations : operations).length > 0 &&
              (isFilterEnabled ? filteredOperations : operations).map(
                (operation) => (
                  <ItemOperation
                    key={operation.operation_json.reference.id}
                    operation={operation}
                    pilotName={
                      getPilotName(
                        operation.operation_json.request.pilot_uuid[0]
                      ) || " "
                    }
                    trackerName={getTrackerName(
                      operation.operation_json.request.tracker_uuid[0]
                    )}
                    isSelected={selectedIds.includes(
                      operation.operation_json.reference.id
                    )}
                    copyOperationId={copyOperationId}
                    onClick={handleOperationClicked}
                    onSelected={handleItemSelected}
                  />
                )
              )}

            {!showOperationList &&
              selectedOption === "Pilot" &&
              pilots.length > 0 &&
              pilots.map((pilot) => (
                <PilotItem
                  key={pilot.pilot_uuid}
                  data={pilot}
                  onClick={handleItemClicked}
                  onSelect={handleItemSelected}
                />
              ))}

            {/* List Of Platforms */}
            {!showOperationList &&
              selectedOption === "Platforms" &&
              platforms.length > 0 &&
              platforms.map((platform) => (
                <PlatformItem
                  key={platform.platform_uuid}
                  data={platform}
                  hasReadMaintenanceRights={userAccess.privileges.includes(
                    "assets.maintenance.read"
                  )}
                  onClick={handleItemClicked}
                  onSelect={handleItemSelected}
                />
              ))}

            {/* RID Operations */}
            {!showOperationList &&
              selectedOption === "Rid Operations" &&
              (isFilterEnabled ? filteredOperations : ridOperations).length >
                0 &&
              (isFilterEnabled ? filteredOperations : ridOperations).map(
                (operation) => (
                  <ItemOperation
                    key={operation.operation_json.reference.id}
                    operation={operation}
                    pilotName={" "}
                    trackerName={getTrackerName(
                      operation.operation_json.request.tracker_uuid[0]
                    )}
                    isSelected={selectedIds.includes(
                      operation.operation_json.reference.id
                    )}
                    copyOperationId={copyOperationId}
                    onClick={handleOperationClicked}
                    onSelected={handleItemSelected}
                  />
                )
              )}

            {!isLoading && operations.length === 0 && (
              <p className="p-8 text-center">No Data Available</p>
            )}

            <Loader
              isLoading={isLoading}
              isError={isError}
              errorText={errorMessage}
            />
          </div>
        </div>

        {!showOperationList && selectedOption === "Rid Operations" ? (
          <div className="h-[10%] px-4 py-2 items-center content-center">
            <div className="flex">
              {/* <Button
                type="light"
                size="medium"
                text="Export JSON"
                className="mr-2"
                onClick={handleExportJSON}
              /> */}
              <Button
                type={!selectedIds.length ? "light" : "primaryDark"}
                size="medium"
                text="Export CSV"
                disabled={!selectedIds.length}
                onClick={handleExportCSV}
              />
            </div>
          </div>
        ) : (
          <div className="h-[10%] px-4 py-2 items-center content-center">
            {!showOperationList &&
              (selectedOption === "Platforms" ||
                selectedOption === "Pilot") && (
                <Button
                  type="primaryDark"
                  size="medium"
                  text="View Statistic"
                  icon={<FaChartBar size={12} color="white" />}
                  onClick={openStatisticModal}
                />
              )}
            {(showOperationList || selectedOption == "All Operation Logs") &&
              userAccess.role !== "airspace_manager" && (
                <div className="flex">
                  <Button
                    type={!(selectedIds.length === 1) ? "light" : "primaryDark"}
                    size="medium"
                    text="Repeat"
                    className="mr-2"
                    // isLoading={isDisplaying}
                    disabled={!(selectedIds.length === 1)}
                    onClick={handleRepeatButtonClick}
                  />

                  <Button
                    type="light"
                    size="medium"
                    fixedWidth={true}
                    className="mr-2 w-20"
                    icon={<IoEllipsisHorizontal size={24} />}
                    onClick={() => setShowMoreOptions(!showMoreOptions)}
                    // isLoading={isDeleting}
                    // disabled={isDeleting || isDisplaying}
                    // onClick={handleDeleteOperation}
                  />
                </div>
              )}
          </div>
        )}
      </div>

      {/* Button Options */}
      {showMoreOptions && (
        <div className="w-40 h-36 absolute bottom-40 right-6 popup-menu">
          {/* Options concisted of export, replay, etc */}
          <ul className="flex-col">
            <li onClick={handleExportJSON}>Export JSON</li>
            <li onClick={handleExportCSV}>Export CSV</li>
            <li onClick={handleExportReplayFlight}>Export Replay Logs</li>
            <li onClick={handleReplayOperation}>Replay Operation</li>
          </ul>
        </div>
      )}
    </div>
  );
}
