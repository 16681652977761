import React, { useState, useEffect } from "react";
import { Amplify, Auth } from "aws-amplify";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

import { getUA } from "react-device-detect";

import { IoMdEye, IoMdEyeOff, IoMdCheckboxOutline } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import { RoundedDatePicker } from "@/components/v1/Common/RoundedDatePicker";
import Button from "@/components/v1/Common/Button";
import { parseJwt } from "@/api/operator";
import { isRegExp } from "util/types";

type UserRegistrationFormType = {
  username: string;
  email: string;
  name?: string;
  contact?: string | number;
  organisation?: string;
  pilot_license?: string;
  license_expiry?: string | number;
  password: string;
  passwordConfirmation?: string;
};

export default function LandingPage() {
  // const navigate = useNavigate();

  const [isAuthority, setIsAuthority] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");

  const [isIncorrentCredentials, setIsIncorrentCredentials] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmationPasswordVisible, setIsConfirmationPasswordVisible] =
    useState(false);
  const [isEulaAccepted, setIsEulaAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Password Strength for Reset Password
  const [hasUppercase, setHasUppercase] = useState<boolean>(false);
  const [hasSymbol, setHasSymbol] = useState<boolean>(false);

  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isRecovery, setIsRecovery] = useState(false);

  // Change Password
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [changePasswordUser, setChangePasswordUser] = useState("");
  const [changePasswordNewPassword, setChangePasswordNewPassword] =
    useState("");
  const [
    changePasswordConfirmationPassword,
    setChangePasswordConfirmationPassword,
  ] = useState("");

  // Verification Required
  const [isVerificationRequired, setIsVerificationRequired] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [resendVerificationTime, setResendVerificationTime] = useState<Date>();
  // Request Account
  const [isRequestAccount, setIsRequestAccount] = useState(false);
  const [requestAccountForm, setRequestAccountForm] =
    useState<UserRegistrationFormType>({
      username: "",
      email: "",
      password: "",
    });

  const DJIDevice = getUA.includes("dji-open-platform");
  const eulaAgreementLink = process.env.REACT_APP_EULA_LINK;
  const disableNewAccRegistration = process.env.REACT_APP_DISABLE_REGISTRATION;

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    const uppercaseCheck = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
    const symbolCheck =
      /^(?=.*\d)(?=.*[\^$*.[\]{}()?!"@#%&/\\,><':;|_~`=+-])/.test(password);

    setHasUppercase(uppercaseCheck);
    setHasSymbol(symbolCheck);
    setPassword(password);
  };

  const handleChangePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const password = e.target.value;
    const uppercaseCheck = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
    const symbolCheck =
      /^(?=.*\d)(?=.*[\^$*.[\]{}()?!"@#%&/\\,><':;|_~`=+-])/.test(password);

    setHasUppercase(uppercaseCheck);
    setHasSymbol(symbolCheck);
    setChangePasswordNewPassword(password);
  };

  const handleAuthTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIsAuthority(true);
      Amplify.configure({
        Auth: {
          region: process.env.REACT_APP_AWS_REGION,
          userPoolId: process.env.REACT_APP_AUTHORITY_COGNITO_POOL_ID,
          userPoolWebClientId:
            process.env.REACT_APP_AUTHORITY_COGNITO_POOL_WEB_CLIENT_ID,
        },
      });
    } else {
      setIsAuthority(false);
      Amplify.configure({
        Auth: {
          region: process.env.REACT_APP_AWS_REGION,
          userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
          userPoolWebClientId: process.env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID,
        },
      });
    }
  };
  async function handleChangePassword() {
    try {
      if (!changePasswordNewPassword || !changePasswordConfirmationPassword) {
        toast.error("Please fill in all fields");
        return;
      }

      setIsLoading(true);

      if (!hasUppercase || !hasSymbol) {
        toast.error("Password does not meet requirements", {
          icon: "❌",
        });
        setIsLoading(false);
        return;
      }

      if (changePasswordNewPassword !== changePasswordConfirmationPassword) {
        toast.error("Passwords do not match", {
          icon: "❌",
        });
        setIsLoading(false);
        return;
      }

      try {
        await Auth.completeNewPassword(
          changePasswordUser,
          changePasswordNewPassword
        );
        toast.success("Password reset successful", {
          icon: "✅",
        });
        setChangePasswordUser("");
        setChangePasswordNewPassword("");
        setChangePasswordConfirmationPassword("");
        setIsChangePassword(false);
        setPassword("");
      } catch (error) {
        toast.error("Error changing password", {
          icon: "❌",
        });
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  // const handleVerifyAccount = async () => {
  //   try {
  //     await Auth.confirmSignUp(username, verificationCode);
  //     handleSignIn();
  //   } catch (err: any) {
  //     toast.error("Invalid code, please try again", {
  //       icon: "❌",
  //     });
  //   }
  // };

  const handleResendVerification = async () => {
    // Prevent spam resend verification
    if (resendVerificationTime) {
      const currentTimeEpoch = new Date().getTime();
      const resendTimeEpoch = new Date(resendVerificationTime).getTime();

      const timeSinceLastSent = currentTimeEpoch - resendTimeEpoch;
      const secondsSinceLastSent = Math.floor(timeSinceLastSent / 1000);
      if (secondsSinceLastSent < 60) {
        toast.error(
          `Please wait ${60 - secondsSinceLastSent}seconds before trying again`
        );
        return;
      }
    }
    try {
      await axios.request({
        method: "post",
        url: `${process.env.REACT_APP_USER_REGISTER_URL}/resend-verification?username=${username}`,
        headers: {
          "content-type": "application/json",
        },
      });
      toast.success("Verification link sent to your email", {
        icon: "📧",
      });

      const currentTime = new Date();
      setResendVerificationTime(currentTime);
    } catch (err) {
      toast.error("Unable to register account");
    }
  };

  const handleSignIn = async () => {
    setIsIncorrentCredentials(false);
    setIsLoading(true);

    try {
      const signInResponse = await Auth.signIn(username, password);
      if (
        signInResponse.challengeName &&
        signInResponse.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        setChangePasswordUser(signInResponse);
        setIsChangePassword(true);
        return;
      }
      await Auth.currentAuthenticatedUser();

      // reload the page
      window.location.reload();
    } catch (error: any) {
      if (error.code === "UserNotConfirmedException") {
        setIsVerificationRequired(true);
        setIsLoading(false);
      } else {
        setIsIncorrentCredentials(true);
        setIsLoading(false);
      }
    }
  };

  const handleForgotPassword = async () => {
    if (!username) {
      toast.error("Please enter your username");
      return;
    }

    setIsLoading(true);
    try {
      await Auth.forgotPassword(username);
      toast.success("Confirmation code sent to your email", {
        icon: "📧",
      });
      setIsForgotPassword(false);
      setIsRecovery(true);
    } catch (error) {
      toast.error("Error sending confirmation code", {
        icon: "❌",
      });
    }
    setIsLoading(false);
  };

  const handleResetPassword = async () => {
    if (!confirmationCode || !password || !confirmationPassword) {
      toast.error("Please fill in all fields");
      return;
    }

    setIsLoading(true);

    if (!hasUppercase || !hasSymbol) {
      toast.error("Password does not meet requirements", {
        icon: "❌",
      });
      setIsLoading(false);
      return;
    }

    if (password !== confirmationPassword) {
      toast.error("Passwords do not match", {
        icon: "❌",
      });
      setIsLoading(false);
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(username, confirmationCode, password);
      toast.success("Password reset successful", {
        icon: "✅",
      });
      setIsRecovery(false);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((error as any).code === "CodeMismatchException") {
        toast.error("Invalid confirmation code", {
          icon: "❌",
        });
      } else {
        toast.error("Error resetting password", {
          icon: "❌",
        });
      }
    }
    setIsLoading(false);
  };

  const handleOldUIChange = () => {
    localStorage.setItem("ui_version", "v0");
    window.location.reload();
  };

  const openComingSoonToast = () => {
    toast.success("Coming Soon", {
      icon: "🚀",
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (isForgotPassword) {
          handleForgotPassword();
        } else if (isRecovery) {
          handleResetPassword();
        } else if (!isChangePassword && !isRequestAccount) {
          handleSignIn();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // cannot read useStates if not added here
  }, [username, password, isForgotPassword, isRecovery]);

  /* Register New Account */
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRequestAccountForm({
      ...requestAccountForm,
      [e.target.name]:
        e.target.type === "number"
          ? parseFloat(e.target.value)
          : e.target.value,
    });
  };

  const handleChangePasswordRegisterAccount = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const password = e.target.value;
    const uppercaseCheck = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
    const symbolCheck =
      /^(?=.*\d)(?=.*[\^$*.[\]{}()?!"@#%&/\\,><':;|_~`=+-])/.test(password);

    setHasUppercase(uppercaseCheck);
    setHasSymbol(symbolCheck);
    setRequestAccountForm({
      ...requestAccountForm,
      password,
    });
  };

  const requestNewAcc = async (captchaToken: string | null) => {
    try {
      await axios.request({
        method: "post",
        url: process.env.REACT_APP_USER_REGISTER_URL,
        headers: {
          "content-type": "application/json",
        },
        data: {
          username: requestAccountForm.username,
          password: requestAccountForm.password,
          email: requestAccountForm.email,
          googleRecaptchaToken: captchaToken,
        },
      });
      setHasUppercase(false);
      setHasSymbol(false);
      setIsRequestAccount(false);
      setIsLoading(false);
      setRequestAccountForm({
        username: "",
        email: "",
        password: "",
        passwordConfirmation: "",
      });
      toast.success("Account creation successful", {
        icon: "✅",
      });
    } catch (err: any) {
      toast.error(`Unable to register account: ${err.response.data.message}`);
      setIsLoading(false);
    }
  };

  const validateRequestForm = () => {
    if (
      !requestAccountForm.username ||
      !requestAccountForm.email ||
      !requestAccountForm.password
    ) {
      toast.error("Please fill in all fields");
      return false;
    }
    if (!isEulaAccepted) {
      toast.error("EULA agreement not accepted");
      return false;
    }
    if (
      requestAccountForm.password !== requestAccountForm.passwordConfirmation
    ) {
      toast.error("Passwords do not match", {
        icon: "❌",
      });
      return false;
    }
    if (!hasSymbol) {
      toast.error("Password does not meet requirements", {
        icon: "❌",
      });
      return false;
    }

    return true;
  };

  const handleRequestAccountClick = () => {
    const formValidated = validateRequestForm();
    if (!formValidated) return;

    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY || ""}
              onChange={(captchaToken) => {
                requestNewAcc(captchaToken);
                toast.dismiss(t.id);
              }}
            />
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          </div>
        </div>
      ),
      {
        duration: Infinity,
      }
    );
  };
  /* Register New Account End */

  return (
    <div className="w-full h-screen flex ">
      <section
        className={`${
          DJIDevice ? "w-3/4 overflow-auto" : "w-1/2"
        }  h-full bg-darkNavy relative p-8`}
      >
        {/* Request account fields too long, hide logo when requesting account */}
        {!isRequestAccount && (
          <img
            src="/HeronLogoWhiteText.png"
            alt="logo"
            width={120}
            height={120}
          />
        )}

        {/* Login Form, visible by default */}
        <div
          className={`absolute top-[20vh] ${
            DJIDevice ? "w-3/4" : "w-[25vw]"
          } transition-opacity ease-in-out delay-150 duration-300 ${
            isForgotPassword ||
            isRecovery ||
            isChangePassword ||
            isRequestAccount ||
            isVerificationRequired
              ? "opacity-0 pointer-events-none"
              : "opacity-100 pointer-events-auto"
          }`}
        >
          {!DJIDevice && (
            <>
              <h2 className="text-white mt-10 mb-2">Welcome to Airbridge</h2>
              <h5 className="text-gray-400 font-medium mb-4">
                Please enter your sign in credentials
              </h5>
            </>
          )}

          <label htmlFor="username" className="text-white flex flex-col mb-4">
            <p className="font-medium p-2">Email or Username</p>
            <input
              type="email"
              name="email"
              id="email"
              className="input-rounded"
              autoComplete="off"
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>

          <div className="relative">
            <label htmlFor="password" className="text-white flex flex-col mb-2">
              <p className="font-medium p-2">Password</p>
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Type here"
                className="input-rounded"
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </label>
            <button
              className="absolute right-4 bottom-3"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? (
                <IoMdEyeOff fill="white" size={22} />
              ) : (
                <IoMdEye fill="white" size={22} />
              )}
            </button>
          </div>

          {isIncorrentCredentials && (
            <span className="mb-2 text-red-400">
              Invalid Username or Password
            </span>
          )}

          <div className="flex mb-8">
            <span className="grow" />
            {/* <a href="#"> */}
            <span
              className="font-medium text-darkLink cursor-pointer"
              onClick={() => setIsForgotPassword(!isForgotPassword)}
            >
              Forgot Password ?
            </span>
            {/* </a> */}
          </div>

          <Button
            text="Sign In"
            type="primaryDark"
            isLoading={isLoading}
            onClick={handleSignIn}
            className="mb-6"
          />
          {!disableNewAccRegistration && (
            <p className="text-white m-auto">
              Don&apos;t have an account ?{" "}
              <span
                className="text-darkLink font-medium cursor-pointer"
                onClick={() => setIsRequestAccount(true)}
              >
                Request Account
              </span>
            </p>
          )}
        </div>

        {/* Forgot Password Form, visible only when isForgotPassword */}
        <div
          className={`absolute top-[30vh] ${
            DJIDevice ? "w-3/4" : "w-[25vw]"
          } transition-opacity ease-in-out delay-150 duration-300 ${
            isForgotPassword
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          } ${isRecovery && "opacity-0 pointer-events-none"}`}
        >
          <h2 className="text-white mt-10 mb-2">Forgot Password</h2>
          <h5 className="text-gray-400 font-medium mb-4">
            Enter your username and we will send you your confirmation code to
            reset your password
          </h5>

          <label htmlFor="username" className="text-white flex flex-col mb-6">
            <p className="font-medium p-2">Username</p>
            <input
              type="text"
              name="username"
              id="username"
              className="input-rounded"
              autoComplete="off"
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>

          <div className="flex content-center items-center">
            <span
              className="text-darkLink font-medium cursor-pointer"
              onClick={() => setIsForgotPassword(false)}
            >
              &#60; Back to Login
            </span>
            <span className="grow" />
            <Button
              text="Continue"
              type="primaryDark"
              isLoading={isLoading}
              onClick={handleForgotPassword}
              fixedWidth={true}
              className="mb-6 w-50"
            />
          </div>
        </div>

        {/* Reset Password Form, visible only when isRecovery */}
        <div
          className={`absolute top-[22vh] ${
            DJIDevice ? "w-3/4" : "w-[25vw]"
          } transition-opacity ease-in-out delay-150 duration-300 ${
            isRecovery
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <h2 className="text-white mt-10 mb-2">Reset Password</h2>
          <h5 className="text-gray-400 font-medium mb-4">
            Please check your email for the confirmation code and enter it below
            to reset your password
          </h5>

          <label htmlFor="password" className="text-white flex flex-col mb-2">
            <p className="font-medium p-2">Confirmation Code</p>
            <input
              type="text"
              name="confirmationCode"
              className="input-rounded"
              autoComplete="off"
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
          </label>

          <div className="relative">
            <label htmlFor="password" className="text-white flex flex-col mb-2">
              <p className="font-medium p-2">New Password</p>
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Type here"
                className="input-rounded"
                autoComplete="off"
                onChange={handleNewPasswordChange}
              />
            </label>
            <button
              className="absolute right-4 bottom-3"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? (
                <IoMdEyeOff fill="white" size={22} />
              ) : (
                <IoMdEye fill="white" size={22} />
              )}
            </button>
          </div>

          <div className="flex-col text-white mb-2 px-2 py-2">
            <p className="text-sm mb-1">
              {hasUppercase ? "✅" : "❌"} Min 8 characters with one uppercase
              and lowercase
            </p>
            <p className="text-sm">
              {hasSymbol ? "✅" : "❌"} Contains one symbol and number
            </p>
          </div>

          <div className="relative">
            <label htmlFor="password" className="text-white flex flex-col mb-6">
              <p className="font-medium p-2">Confirm Password</p>
              <input
                type={isConfirmationPasswordVisible ? "text" : "password"}
                placeholder="Type here"
                className="input-rounded"
                autoComplete="off"
                onChange={(e) => setConfirmationPassword(e.target.value)}
              />
            </label>
            <button
              className="absolute right-4 bottom-3"
              onClick={() =>
                setIsConfirmationPasswordVisible(!isConfirmationPasswordVisible)
              }
            >
              {isConfirmationPasswordVisible ? (
                <IoMdEyeOff fill="white" size={22} />
              ) : (
                <IoMdEye fill="white" size={22} />
              )}
            </button>
          </div>

          <div className="flex content-center items-center">
            <span
              className="text-darkLink font-medium cursor-pointer"
              onClick={() => {
                setIsForgotPassword(false);
                setIsRecovery(false);
              }}
            >
              &#60; Back to Login
            </span>
            <span className="grow" />
            <Button
              text="Reset Password"
              type="primaryDark"
              isLoading={isLoading}
              onClick={handleResetPassword}
              fixedWidth={true}
              className="mb-6 w-50"
            />
          </div>
        </div>

        {/* Verification Code Form, visible only when isVerificationRequired */}
        <div
          className={`absolute top-[22vh] ${
            DJIDevice ? "w-3/4" : "w-[25vw]"
          } transition-opacity ease-in-out delay-150 duration-300 ${
            isVerificationRequired
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <h2 className="text-white mt-10 mb-2">Account Not Verified</h2>
          <h5 className="text-gray-400 font-medium mb-4">
            Please verify account with link send via email.
          </h5>

          <label htmlFor="username" className="text-white flex flex-col mb-2">
            <p className="font-medium p-2">
              If you did not recieve the verification email, click the link
              below to resend verification email.
            </p>
            <div className="flex mb-8">
              <span className="grow" />
              {/* <a href="#"> */}
              <span
                className="font-medium text-darkLink cursor-pointer"
                onClick={() => handleResendVerification()}
              >
                Resend Verification Email
              </span>
              {/* </a> */}
            </div>
          </label>

          <div className="flex content-center items-center">
            <span
              className="text-darkLink font-medium cursor-pointer"
              onClick={() => {
                setIsForgotPassword(false);
                setIsRecovery(false);
                setIsChangePassword(false);
                setIsLoading(false);
                setIsRequestAccount(false);
                setIsVerificationRequired(false);
              }}
            >
              &#60; Back to Login
            </span>
            <span className="grow" />
            {/* <Button
              text="Submit"
              type="primaryDark"
              onClick={() => handleVerifyAccount()}
              fixedWidth={true}
              className="mb-6 mt-6 w-50"
            /> */}
          </div>
        </div>

        {/* Change Password Form, visible only when isChangePassword */}
        <div
          className={`absolute top-[22vh] ${
            DJIDevice ? "w-3/4" : "w-[25vw]"
          } transition-opacity ease-in-out delay-150 duration-300 ${
            isChangePassword
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <h2 className="text-white mt-10 mb-2">Change Password Required</h2>
          <h5 className="text-gray-400 font-medium mb-4">
            Please enter new password
          </h5>

          <div className="relative">
            <label htmlFor="password" className="text-white flex flex-col mb-2">
              <p className="font-medium p-2">New Password</p>
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Type here"
                className="input-rounded"
                autoComplete="off"
                onChange={handleChangePasswordChange}
              />
            </label>
            <button
              className="absolute right-4 bottom-3"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? (
                <IoMdEyeOff fill="white" size={22} />
              ) : (
                <IoMdEye fill="white" size={22} />
              )}
            </button>
          </div>

          <div className="flex-col text-white mb-2 px-2 py-2">
            <p className="text-sm mb-1">
              {hasUppercase ? "✅" : "❌"} Min 8 characters with one uppercase
              and lowercase
            </p>
            <p className="text-sm">
              {hasSymbol ? "✅" : "❌"} Contains one symbol and number
            </p>
          </div>

          <div className="relative">
            <label htmlFor="password" className="text-white flex flex-col mb-6">
              <p className="font-medium p-2">Confirm Password</p>
              <input
                type={isConfirmationPasswordVisible ? "text" : "password"}
                placeholder="Type here"
                className="input-rounded"
                autoComplete="off"
                onChange={(e) =>
                  setChangePasswordConfirmationPassword(e.target.value)
                }
              />
            </label>
            <button
              className="absolute right-4 bottom-3"
              onClick={() =>
                setIsConfirmationPasswordVisible(!isConfirmationPasswordVisible)
              }
            >
              {isConfirmationPasswordVisible ? (
                <IoMdEyeOff fill="white" size={22} />
              ) : (
                <IoMdEye fill="white" size={22} />
              )}
            </button>
          </div>

          <div className="flex content-center items-center">
            <span
              className="text-darkLink font-medium cursor-pointer"
              onClick={() => {
                setIsForgotPassword(false);
                setIsRecovery(false);
                setIsChangePassword(false);
                setIsLoading(false);
                setIsRequestAccount(false);
                setIsVerificationRequired(false);
              }}
            >
              &#60; Back to Login
            </span>
            <span className="grow" />
            <Button
              text="Change Password"
              type="primaryDark"
              onClick={() => handleChangePassword()}
              fixedWidth={true}
              className="mb-6 w-50"
            />
          </div>
        </div>

        {/* Create Account Form, visible only when isRequestAccount */}
        <div
          className={`absolute top-[5vh] ${
            DJIDevice ? "w-3/4" : "w-[25vw]"
          } transition-opacity ease-in-out delay-150 duration-300 ${
            isRequestAccount
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <h2 className="text-white mb-2">Enter Account Details</h2>
          <h5 className="text-gray-400 font-medium mb-4">
            Please enter details for new account
          </h5>
          <label htmlFor="username" className="text-white flex flex-col mb-1">
            <p className="font-medium p-2">Username</p>
            <input
              type="text"
              name="username"
              id="registerusername"
              className="input-rounded"
              autoComplete="off"
              value={requestAccountForm.username}
              onChange={handleTextChange}
            />
          </label>

          <label htmlFor="username" className="text-white flex flex-col mb-2">
            <p className="font-medium p-2">Email</p>
            <input
              type="text"
              name="email"
              id="registeremail"
              className="input-rounded"
              autoComplete="off"
              value={requestAccountForm.email}
              onChange={handleTextChange}
            />
          </label>

          <div className="relative">
            <label htmlFor="password" className="text-white flex flex-col mb-2">
              <p className="font-medium p-2">Password</p>
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Type here"
                name="password"
                id="registerpassword"
                className="input-rounded"
                autoComplete="off"
                value={requestAccountForm.password}
                onChange={handleChangePasswordRegisterAccount}
              />
            </label>
            <button
              className="absolute right-4 bottom-3"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? (
                <IoMdEyeOff fill="white" size={22} />
              ) : (
                <IoMdEye fill="white" size={22} />
              )}
            </button>
          </div>

          <div className="flex-col text-white mb-2 px-2 py-2">
            <p className="text-sm mb-1">
              {hasUppercase ? "✅" : "❌"} Min 8 characters with one uppercase
              and lowercase
            </p>
            <p className="text-sm">
              {hasSymbol ? "✅" : "❌"} Contains one symbol and number
            </p>
          </div>

          <div className="relative">
            <label htmlFor="password" className="text-white flex flex-col mb-6">
              <p className="font-medium p-2">Confirm Password</p>
              <input
                type={isConfirmationPasswordVisible ? "text" : "password"}
                placeholder="Type here"
                className="input-rounded"
                name="passwordConfirmation"
                id="registerpasswordconfirmation"
                value={requestAccountForm.passwordConfirmation}
                autoComplete="off"
                onChange={handleTextChange}
              />
            </label>
            <button
              className="absolute right-4 bottom-3"
              onClick={() =>
                setIsConfirmationPasswordVisible(!isConfirmationPasswordVisible)
              }
            >
              {isConfirmationPasswordVisible ? (
                <IoMdEyeOff fill="white" size={22} />
              ) : (
                <IoMdEye fill="white" size={22} />
              )}
            </button>
          </div>

          <div className="relative">
            <div className="flex-col text-white mb-2 px-2 py-2">
              <p className="text-sm mb-1 flex items-center gap-2">
                <button
                  className="relative"
                  onClick={() => setIsEulaAccepted(!isEulaAccepted)}
                >
                  {isEulaAccepted ? (
                    <IoMdCheckboxOutline fill="white" size={22} />
                  ) : (
                    <MdCheckBoxOutlineBlank fill="white" size={22} />
                  )}
                </button>
                <span className="flex-wrap font-small ">
                  By checking this box, you are agreeing to the terms and
                  conditions outlined in{" "}
                  <a
                    href={eulaAgreementLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-small text-blue-400 underline hover:text-blue-300"
                  >
                    End-User License Agreement (EULA)
                  </a>
                  .
                </span>
              </p>
            </div>
          </div>

          <div className="flex content-center items-center">
            <span
              className="text-darkLink font-medium cursor-pointer"
              onClick={() => {
                setIsForgotPassword(false);
                setIsRecovery(false);
                setIsChangePassword(false);
                setIsLoading(false);
                setIsRequestAccount(false);
                setIsVerificationRequired(false);
                setIsEulaAccepted(false);
              }}
            >
              &#60; Back to Login
            </span>
            <span className="grow" />
            <Button
              text="Create Account"
              isLoading={isLoading}
              type="primaryDark"
              onClick={() => handleRequestAccountClick()}
              fixedWidth={true}
              className="mb-6 w-50"
            />
          </div>
        </div>

        {/* {!isForgotPassword &&
          !isRecovery &&
          !isChangePassword &&
          !isRequestAccount &&
          !isVerificationRequired && (
            <div className="absolute bottom-2 w-fit py-4">
              <span
                className="text-darkLink font-medium cursor-pointer"
                onClick={handleOldUIChange}
              >
                &#60; Use Old UI
              </span>
            </div>
          )} */}
      </section>
      <section
        className={`${
          DJIDevice ? "w-1/4" : "w-1/2"
        } h-full bg-gray-200 flex items-center justify-center relative`}
      >
        <img
          src="/bg_topography.png"
          alt="topography-background"
          className="w-full h-full object-cover pointer-events-none"
        />
        <img
          src="/intro_slide_1.png"
          alt="intro_1"
          height={250}
          width={100}
          className="w-fit h-fit absolute m-auto pointer-events-none object-contain"
        />
      </section>
      <Toaster />
    </div>
  );
}
