import { Auth } from "aws-amplify";

import Pilot from "@/model/api/Pilot";
import Platform from "@/model/api/Platform";
import Tracker from "@/model/api/Tracker";
import useWebsocket from "@/hooks/useWebsocket";
import UserAccess from "@/model/UserAccess";
import { channels } from "@/config/channels";
import { messageTypes } from "@/config/messageTypes";
import { useEffect, useState, useRef } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { MdOutlineSort } from "react-icons/md";
import { IconButton } from "@mui/material";
import { PiDrone } from "react-icons/pi";
import { OperationJson } from "@/model/api/Operation";
import MapState from "@/model/MapState";
import { useSelector } from "react-redux";
import { store } from "@/store";
import {
  downloadSingleJSON,
  downloadKML,
  downloadKMLURL,
  downloadJSONURL,
  uploadKMLtoCloudDeconflict,
  uploadJSONtoCloudDeconflict,
  uploadKMLtoDJICloudDeconflict,
} from "@/services/json";
import PilotItem from "../AssetManagement/ItemCards/PilotItem";
import OperationPanelItem from "./OperationPanelItem";
import OperationPanelDetail from "./OperationPanelDetail";
import DJILiveStreaming from "../../../../pages/Protected/Drone_Integration/DJILiveStreaming";
import Button from "../../Common/Button";

interface OperationPanelProps {
  api: any;
  isAuthority?: boolean;
  groundSummary: number;
  operationsSummary: number;
  selectedFlightData?: OperationJson[];
  selectedPlatformData?: PlatformsData;
  flightDataIsLoading: boolean;
  platformList?: { [key: string]: Platform } | null;
  trackerList?: { [key: string]: Tracker } | null;
  pilotList?: { [key: string]: Pilot } | null;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
}

interface UserAccessState {
  userAccess: UserAccess;
}

export default function OperationPanel({
  api,
  isAuthority,
  groundSummary,
  operationsSummary,
  selectedFlightData,
  selectedPlatformData,
  flightDataIsLoading,
  platformList,
  trackerList,
  pilotList,
  showMessage,
  onOpenModal,
  onCloseModal,
}: OperationPanelProps) {
  const mapboxController = useSelector(
    (state: MapState) => state.maps.mapboxController
  );
  const { channel } = useWebsocket({ channel: channels.NOTIFICATION_CHANNEL });
  const [telemetryData, setTelemetryData] = useState({});

  const [env, setEnv] = useState<any>([]);

  const [operationPanelExpanded, setOperationPanelExpanded] = useState(false);
  const [viewOperationDetail, setViewOperationDetail] = useState(false);
  const [opPanelSize, setOpPanelSize] = useState(
    "h-4 mx-2 rounded-t-none rounded-b-lg"
  );

  const [uasID, setUasID] = useState("");
  const [udpPort, setUdpPort] = useState("");
  const [userPlatformSN, setUserPlatformSN] = useState("");

  /* Operation */
  const [currentOperation, setCurrentOperation] =
    useState<OperationJson | null>(null);
  /* Operation Button Loading */
  const [isTakeOffLoading, setIsTakeOffLoading] = useState(false);
  const [isLandedLoading, setIsLandedLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isContingentLoading, setIsContingentLoading] = useState(false);
  const [isMavlink, setIsMavlink] = useState(false);
  // Operation Panel Filter
  const [isOnlyOperations, setIsOnlyOperation] = useState(true);
  const [isOnlyRid, setIsOnlyRid] = useState(true);
  const [trackersInOperation, setTrackersInOperation] = useState<string[]>([]);
  // Operation Summary
  const [airborneSummary, SetAirborneSummary] = useState(0);
  /* DJI Upload Operation */
  // const [selectedPilotsForUpload, setSelectedPilotsForUpload] = useState<
  //   string[]
  // >([]);
  const selectedPilotsForUpload = useRef<string[]>([]);
  const keyRef = useRef<any>({});
  const teleObjRef = useRef<any>({});

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);
  const envVar = useSelector((state: any) => state.envVar);

  useEffect(() => {
    const listOfTrackersInOperations = selectedFlightData?.flatMap(
      (singleFlightData) => {
        if (singleFlightData.reference.tag?.toLowerCase() === "rid") return [];
        return singleFlightData.details.platform_tracker_pairs.map(
          (singleTracker) => singleTracker.tracker_sn
        );
      }
    );
    setTrackersInOperation(listOfTrackersInOperations || []);
    // handle operation removed from operation selection but still in operation details
    if (!currentOperation) return;
    const selectedOperationEnded = selectedFlightData?.find(
      (singleFlightData) => {
        return singleFlightData.reference.id === currentOperation.reference.id;
      }
    );
    if (!selectedOperationEnded) {
      showOperationList();
    }
  }, [selectedFlightData]);

  /* Handler Function */
  const handleToggleOperationPanel = () => {
    setOperationPanelExpanded(!operationPanelExpanded);
    if (!operationPanelExpanded) {
      setOpPanelSize("h-[400px] mx-0 mt-1 rounded-lg");
    } else {
      setOpPanelSize("h-4 mx-2 rounded-t-none rounded-b-lg");
    }
  };

  const handleOnFocus = (clearFocus = false) => {
    if (mapboxController) {
      if (!clearFocus) {
        mapboxController.setFocussedOperation(currentOperation);
      } else {
        mapboxController.setFocussedOperation(null);
      }
    }
  };

  const handleOnFocusRemoteId = (lat: number, lng: number) => {
    if (mapboxController) {
      mapboxController.flyTo([lng, lat]);
    }
  };

  const handleTakeOff = () => {
    const content = (
      <div className="flex-col">
        <label className="label">
          Enter the UAS ID from your FlyItSafe App, if applicable
        </label>
        <input
          type="text"
          className="input-text mb-2"
          placeholder="UAS ID"
          onChange={(e) => setUasID(e.target.value)}
        />

        <div className="flex">
          <Button
            type="light"
            size="small"
            fixedWidth={true}
            className="w-32"
            text="Skip"
            onClick={() => submitTakeOff(true)}
          />
          <span className="grow" />
          <Button
            type="light"
            size="small"
            fixedWidth={true}
            className="w-32 mr-2"
            text="Cancel"
            onClick={onCloseModal}
          />
          <Button
            type="primaryDark"
            size="small"
            fixedWidth={true}
            className="w-32"
            text="Submit"
            onClick={submitTakeOff}
          />
        </div>
      </div>
    );
    onOpenModal("Take Off", content, false, true);
  };

  const handleLiveStream = (pilot_uuid: string) => {
    const content = (
      <>
        <div className="flex" onClick={(e) => e.stopPropagation()}>
          <DJILiveStreaming userUuid={pilot_uuid} />
        </div>
      </>
    );

    onOpenModal("Live Stream", content);
  };

  const handleMavlink = async () => {
    if (!currentOperation) return;
    const mavLinkEc2 = envVar["base_url-mavlink-ec2"].Value;
    // Fetch required data from the current operation and platform
    const platformUuid = currentOperation.request.platform_uuid[0];
    const trackerID =
      currentOperation.details.platform_tracker_pairs[0].tracker_sn;
    const { altitude, waypoints } = currentOperation.request;
    const groundSpeed = currentOperation.request.ground_speed;
    // Generate UDP Port using platform uuid
    const numericPlatformUuid = platformUuid.replace(/\D/g, "");
    // Take the 1st 3 digits from uuid
    const platformUuidFragment = numericPlatformUuid.slice(0, 3);
    // UDP = 1 + 1st 3 Digits from uuid + 0
    const udpPort = `1${platformUuidFragment}0`;
    const ipAddress = `${mavLinkEc2}:${udpPort}`; // Change it based on EC2 Instance IP Address
    const result = await api.getPlatform(platformUuid);
    const SysFCSN = result.data.flight_controller_sn;
    const FlightControllerSN = SysFCSN;
    const platformName = result.data.platform_callsign;
    const content = (
      <div className="flex-col">
        {/* Display the UDP Connection Port */}
        <div className="mb-4">
          <label className="label">MavLink UDP Connection Port</label>
          <div className="input-text bg-gray-200 p-2 rounded">{ipAddress}</div>
        </div>

        {/* Label for Serial Number Input */}
        <label className="label">
          Enter the Flight Controller Serial Number
        </label>

        {/* Input Field for Platform Serial Number (Pre-filled but editable) */}
        <input
          type="text"
          className="input-text mb-2"
          placeholder="Platform Serial Number"
          value={FlightControllerSN} // Pre-fill the input with the system serial number
          onChange={(e) => setUserPlatformSN(e.target.value)} // Allow user to edit
        />
        {/* Buttons */}
        <div className="flex">
          <span className="grow" />
          {/* Cancel Button */}
          <Button
            type="light"
            size="small"
            fixedWidth={true}
            className="w-32 mr-2"
            text="Cancel"
            onClick={onCloseModal}
          />
          {/* Submit Button */}
          <Button
            type="primaryDark"
            size="small"
            fixedWidth={true}
            className="w-32"
            text="Submit"
            onClick={() =>
              submitMavlink({
                trackerID,
                FlightControllerSN,
                platformName,
                udpPort,
                altitude,
                groundSpeed,
                waypoints,
              })
            }
          />
        </div>
      </div>
    );

    // Open the modal with content for "Mavlink Connect"
    onOpenModal("Mavlink Connect", content, false, true);
  };

  const handleLandOperation = async () => {
    try {
      showMessage?.("Land Request Sent");
      setIsLandedLoading(true);
      const res = await api.updateOperation(
        currentOperation?.reference.id,
        "Ended"
      );
      setIsLandedLoading(false);
      if (res.status === 200) {
        showMessage?.("Land Request Successful", true);
        return true;
      }
    } catch (err) {
      showMessage?.(
        `Unable to notify MARS of operation state change - ${err}`,
        false,
        true
      );
      setIsLandedLoading(false);
      return false;
    }
  };

  const handleContingentOperation = () => {
    const content = (
      <div className="flex-col">
        <label className="label">
          Set the state of the operation to contingent? A contingent volume will
          be generated and nearby affected flights will be notified of your
          contingent operation.
        </label>
        <div className="flex">
          <span className="grow" />
          <Button
            type="light"
            size="small"
            fixedWidth={true}
            className="w-32 mr-2"
            text="Cancel"
            onClick={onCloseModal}
          />
          <Button
            type="primaryDark"
            size="small"
            fixedWidth={true}
            className="w-32"
            text="Submit"
            onClick={submitContingentOperation}
          />
        </div>
      </div>
    );
    onOpenModal("Contingent", content, false, true);
  };

  const submitContingentOperation = async () => {
    try {
      setIsContingentLoading(true);
      onCloseModal();
      const res = isAuthority
        ? await api.aas.setContingent(currentOperation?.reference.id)
        : await api.updateOperation(
            currentOperation?.reference.id,
            "Contingent"
          );
    } catch (err) {
      showMessage?.(
        "Unable to notify MARS of operation state change",
        false,
        true
      );
    }
    setIsContingentLoading(false);
  };

  const submitMavlink = async ({
    trackerID,
    FlightControllerSN,
    platformName,
    udpPort,
    altitude,
    groundSpeed,
    waypoints,
  }: {
    trackerID: string;
    FlightControllerSN: string;
    platformName: string;
    udpPort: string;
    altitude: number;
    groundSpeed: number;
    waypoints: number[][];
  }) => {
    try {
      showMessage?.("Setting up MAVLink Connection...");
      await api.postMavlinkConnection(
        trackerID,
        FlightControllerSN,
        platformName,
        udpPort,
        altitude,
        groundSpeed,
        waypoints
      );
      console.log("MAVLink connection successfully set up");
      showMessage?.("MAVLink connection successfully set up", true);
      onCloseModal();
    } catch (error) {
      console.error("Error while submitting MAVLink data:", error);
      showMessage?.("Failed to set up MAVLink Connection", false, true);
    } finally {
      setIsMavlink(false);
    }
  };

  const submitTakeOff = async (skip = false) => {
    if (!currentOperation) return;
    if (!skip) {
      if (uasID === "") {
        showMessage?.("UAS ID is required", false, true);
        return;
      }
    }

    onCloseModal();
    showMessage?.("Take Off Request Sent");

    try {
      setIsTakeOffLoading(true);
      await api.postMissionDetails(currentOperation, false, env, uasID);
      const res = await api.updateOperation(
        currentOperation.reference.id,
        "Activated"
      );
      if (res?.status === 200) {
        showMessage?.("Take Off Request Sent Succesfully", true);
      } else {
        showMessage?.(
          "Unable to notify MARS of operation state change",
          false,
          true
        );
      }

      // TODO: handle the res
    } catch (error) {
      showMessage?.(
        `Unable to notify MARS of operation state change - ${error}`,
        false,
        true
      );
    }
    setIsTakeOffLoading(false);
  };

  const handleRemoveOperationFromList = async () => {
    if (
      currentOperation?.details.state !== "Proposed" &&
      currentOperation?.details.state !== "Rejected" &&
      currentOperation?.details.state !== "Accepted" &&
      currentOperation?.details.state !== "Ended"
    ) {
      showMessage?.(`Cannot remove an ongoing operation`, false, true);
      return;
    }
    try {
      setIsCancelLoading(true);
      const res = await api.removeOperationSelection(
        currentOperation?.reference.id
      );
      if (res) {
        showMessage?.("Operation removed from Operation Panel");
      }
    } catch (err) {
      console.log(err);
    }
    setIsCancelLoading(false);
  };

  const handleDownloadOperation = async () => {
    selectedPilotsForUpload.current = [];
    if (!currentOperation) {
      console.log("Error current operation empty");
      return;
    }
    try {
      const response = await api.getOperationData({
        operationID: currentOperation?.reference.id,
      });
      /* Handlers for download */
      const handleDownloadJSONClick = () => {
        downloadSingleJSON(
          response.data,
          currentOperation.reference.description
        );
        onCloseModal();
      };

      const handleDownloadKMLClick = () => {
        downloadKML(
          { operation_json: response.data },
          currentOperation.reference.description
        );
        onCloseModal();
      };

      const handleDjiUploadClick = () => {
        onOpenModal(
          "Select Pilot/s for Operation",
          djiCloudSelectionModal,
          false,
          true
        );
      };

      const downloadSelectionModal = (
        <div className="flex-col">
          <label className="label">Select Download Option</label>
          <div className="flex mb-4">
            <Button
              type="primaryDark"
              size="small"
              fixedWidth={true}
              className="w-32 m-1"
              text="JSON"
              onClick={handleDownloadJSONClick}
            />
            {currentOperation?.details.waypoints && (
              <>
                <Button
                  type="primaryDark"
                  size="small"
                  fixedWidth={true}
                  className="w-32 m-1"
                  text="KML"
                  onClick={handleDownloadKMLClick}
                />
                <Button
                  type="primaryDark"
                  size="small"
                  fixedWidth={true}
                  className="w-32 m-1"
                  text="DJI Cloud"
                  onClick={handleDjiUploadClick}
                />
              </>
            )}
          </div>
          <div className="flex">
            <span className="grow" />
            <Button
              type="light"
              size="small"
              fixedWidth={true}
              className="w-32 mr-2"
              text="Cancel"
              onClick={onCloseModal}
            />
          </div>
        </div>
      );
      /* Handlers for DJI Cloud Upload */
      const handleSelectPilot = (id: string, isSelected: boolean) => {
        if (!pilotList) return [];
        if (isSelected) {
          const selectedPilotsForUploadEdited = selectedPilotsForUpload.current;
          selectedPilotsForUploadEdited.push(pilotList[id].pilot_username);
          selectedPilotsForUpload.current = selectedPilotsForUploadEdited;
        } else {
          const selectedPilotsForUploadEdited =
            selectedPilotsForUpload.current.filter(
              (itemId: string) => itemId !== pilotList[id].pilot_username
            );
          selectedPilotsForUpload.current = selectedPilotsForUploadEdited;
        }
      };

      const handleUploadOperationClick = async () => {
        await uploadDjiCloudOperation();
        onCloseModal();
      };

      const djiCloudSelectionModal = (
        <div className="flex-col">
          <label className="label">Select Pilot to Upload to (Optional)</label>
          {pilotList && (
            <div className="h-[50%] max-h-80 flex-col overflow-auto p-4 mb-4">
              {Object.keys(pilotList).length > 0 &&
                Object.keys(pilotList).map((singlePilotUuid) => (
                  <PilotItem
                    key={pilotList[singlePilotUuid].pilot_username}
                    data={pilotList[singlePilotUuid]}
                    // onClick={handleSelectPilot}
                    onSelect={handleSelectPilot}
                  />
                ))}
            </div>
          )}
          <div className="flex">
            <span>
              <Button
                type="light"
                size="small"
                fixedWidth={true}
                className="w-32 mr-2"
                text="Cancel"
                onClick={onCloseModal}
              />
              <Button
                type="light"
                size="small"
                fixedWidth={true}
                className="w-32 mr-2"
                text="Upload"
                onClick={handleUploadOperationClick}
              />
            </span>
          </div>
        </div>
      );

      onOpenModal(
        "Select Download Option",
        downloadSelectionModal,
        false,
        true
      );
    } catch {
      console.log("Error downloading file");
    }
  };

  const uploadDjiCloudOperation = async () => {
    if (!currentOperation) return;
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const listOfUsersToUpload = [
      ...selectedPilotsForUpload.current,
      cognitoUser.username,
    ];
    for (let i = 0; i < listOfUsersToUpload.length; i += 1) {
      const user = listOfUsersToUpload[i];
      if (user.length) {
        uploadKMLtoDJICloudDeconflict(
          { operation_json: currentOperation },
          currentOperation.reference.description,
          user,
          api
        );
      }
    }
  };

  const showOperationList = () => {
    setViewOperationDetail(false);
    setCurrentOperation(null);
    if (mapboxController) {
      mapboxController.setFocussedOperation(null);
    }
  };

  const showOperationDetail = (flightData: OperationJson) => {
    setViewOperationDetail(true);
    setCurrentOperation(flightData);
  };

  const getPilotName = (pilotId?: string) => {
    if (!pilotList) return "Unknown Pilot";
    if (!pilotId) return "Unknown Pilot";
    return pilotList[pilotId]?.pilot_username || "No Pilot Data";
  };

  const getTrackerName = (trackerId?: string) => {
    if (!trackerList) return "Unknown Tracker";
    if (!trackerId) return "Unknown Tracker";
    return trackerList[trackerId]?.tracker_name;
  };

  const getPlatformName = (platformId?: string) => {
    if (!platformList) return "Unknown Platform";
    if (!platformId) return "Unknown Platform";
    return platformList[platformId]?.platform_callsign;
  };

  useEffect(() => {
    if (!store.getState().envVar["base_url-sp"]) return;
    const currenteEnvironment =
      store.getState().envVar["base_url-sp"].Value ===
      "https://sp.heronairbridge.com"
        ? "prod"
        : "dev";
    setEnv(currenteEnvironment);
  }, []);

  /* Handle Telemetry */

  useEffect(() => {
    const checkTrackerImei = (trackerImei: string) => {
      const dataTracker: any[] = [];
      // eslint-disable-next-line func-names
      const filterDataTracker = dataTracker.filter(function (tracker) {
        return tracker.data.tracker_imei === trackerImei;
      });
      return filterDataTracker;
    };

    const handleMessage = (data: any) => {
      if (!data) return;
      if (data?.operational_status !== "Undeclared") {
        const checkPlatformUuid = (puck_uuid: string) => {
          const dataPlatform: any[] = [];
          selectedPlatformData?.map((p, index) => {
            return p.map((datas) => {
              return dataPlatform.push(datas);
            });
          });
          // eslint-disable-next-line func-names
          const filterDataPlatform = dataPlatform.filter(function (platform) {
            return platform?.data?.puck_uuid === puck_uuid;
          });
          return filterDataPlatform;
        };
        // key only contains serial number of tracker
        const key = data.extras.tracker_sn || "unknown-tracker";
        if (!keyRef.current[key]) {
          keyRef.current = {
            ...keyRef.current,
            [key]: true,
          };
        }
        teleObjRef.current = {
          ...teleObjRef.current,
          [key]: {
            gufi: data.operation_id,
            trackerSn: data.extras?.tracker_sn,
            trackerImei: data.extras?.imei,
            timestamp: Date.now(),
            puckResponses: [
              [
                null,
                {
                  error: null,
                  ...data,
                },
                checkPlatformUuid(data.extras.imei),
                checkTrackerImei(data.extras.imei)
                  ? checkTrackerImei(data.extras.imei)
                  : [],
              ],
            ],
          },
        };
      }
    };

    const removeListener = channel?.addMessageListener(
      messageTypes.TELEMETRY,
      handleMessage
    );

    return () => {
      removeListener?.();
    };
  }, [channel, userAccess]);

  useEffect(() => {
    // This effect runs every 1 second
    const intervalId = setInterval(() => {
      Object.keys(teleObjRef.current).forEach((k) => {
        if (
          Date.now() - (teleObjRef.current[k]?.timestamp || Date.now()) >
          20000
        )
          delete teleObjRef.current[k];
      });
      setTelemetryData(teleObjRef.current);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    // airborne summary requirements
    // 1. if tracker above 2m is considered air (from takeoff point reference)
    // 2. rid operations do not count towards air/ground summary
    // 3. if operation has multiple tracker, will still count as 1 air
    let dataAirborneSummary = 0;
    selectedFlightData?.forEach((singleFlightData) => {
      if (singleFlightData.reference.tag?.toLowerCase() === "rid") return;
      if (
        singleFlightData.details.platform_tracker_pairs.some(
          (singleTracker) =>
            teleObjRef?.current[singleTracker.tracker_sn]?.puckResponses[0][1]
              ?.height?.distance >= 2
        )
      )
        dataAirborneSummary += 1;
    });
    SetAirborneSummary(dataAirborneSummary);
  }, [selectedFlightData, teleObjRef]);

  // TODO - currently add this as handler for remoteId Operations
  // Update this once remote ID no longer require creating operation
  const remoteIdCheck = () => {
    if (
      currentOperation &&
      currentOperation.reference.description.includes("Monitor: ")
    )
      return true;
    return false;
  };

  const filterOperationRidDuplicateTrackers = (flightData: OperationJson) => {
    if (flightData?.reference?.tag?.toLowerCase() === "operation") return true;
    if (
      trackersInOperation.includes(
        flightData.details.platform_tracker_pairs[0].tracker_sn
      )
    )
      return false;
    return true;
  };

  return (
    <div className="flex-col w-[350px]">
      <div
        className="w-full rounded-container p-2 flex items-center content-center select-none cursor-pointer"
        onClick={handleToggleOperationPanel}
      >
        <div className="rounded-lg bg-blue-200 h-14 w-14 content-center items-center">
          <PiDrone size={28} className="m-auto fill-blue-500" />
        </div>
        <div className="flex flex-col px-4 grow">
          <h5 className="font-medium text-lg">Operation Panel</h5>
          <p>Monitor Active Operation</p>
        </div>
        {operationPanelExpanded ? (
          <IoIosArrowUp size={20} color="gray" className="mr-2" />
        ) : (
          <IoIosArrowDown size={20} color="gray" className="mr-2" />
        )}
      </div>
      <div
        className={`bg-white dark:bg-darkSecondary flex-col border-gray2 dark:border-darkTertiary border p-2 transition-all duration-500 ease-in-out ${opPanelSize}`}
      >
        {operationPanelExpanded &&
          (viewOperationDetail ? (
            <OperationPanelDetail
              api={api}
              operation={currentOperation}
              getTrackerName={getTrackerName}
              getPlatformName={getPlatformName}
              getPilotName={getPilotName}
              onReturn={showOperationList}
              onFocus={handleOnFocus}
              onFocusRemoteId={handleOnFocusRemoteId}
              onTakeOff={handleTakeOff}
              onLiveStream={handleLiveStream}
              onMavlink={handleMavlink}
              onCancel={handleRemoveOperationFromList}
              onLandOperation={handleLandOperation}
              onContingentClick={handleContingentOperation}
              onDownloadClick={handleDownloadOperation}
              showMessage={showMessage}
              isTakeOffLoading={isTakeOffLoading}
              isLandedLoading={isLandedLoading}
              isCancelLoading={isCancelLoading}
              isContingentLoading={isContingentLoading}
              isRemoteId={remoteIdCheck()}
              isAuthority={isAuthority}
              telemetryData={telemetryData}
              onOpenModal={onOpenModal}
              onCloseModal={onCloseModal}
            />
          ) : (
            <div className="flex-col h-[100%]">
              <div className="mb-3 flex items-center justify-between p-2 dark:text-white">
                <span>
                  Air : <b>{airborneSummary}</b>
                </span>
                <span>
                  Ground : <b>{operationsSummary - airborneSummary}</b>
                </span>
                <span>
                  Total : <b>{operationsSummary}</b>
                </span>
                {/* <button>
                  <MdOutlineSort size={18} color="gray" />
                </button> */}
                <span>
                  <IconButton onClick={() => setIsOnlyRid(!isOnlyRid)}>
                    <img
                      src="/sidebar-menu/menu_tracker_light_active.png"
                      alt="icon"
                      className={`w-5 h-5 pointer-events-none select-none ${
                        !isOnlyRid ? "grayscale opacity-50" : ""
                      }`}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => setIsOnlyOperation(!isOnlyOperations)}
                  >
                    <img
                      src="/sidebar-menu/menu_drone_light_active.png"
                      alt="icon"
                      className={`w-5 h-5 pointer-events-none select-none ${
                        !isOnlyOperations ? "grayscale opacity-50" : ""
                      }`}
                    />
                  </IconButton>
                </span>
              </div>

              <div className="flex-col overflow-auto h-[90%] pb-6">
                {selectedFlightData
                  ?.filter((flightData) => {
                    if (!isOnlyOperations && isOnlyRid) return true;
                    return filterOperationRidDuplicateTrackers(flightData);
                  })
                  ?.filter(
                    (flightData) =>
                      isOnlyOperations ||
                      flightData?.reference?.tag?.toLowerCase() !== "operation"
                  )
                  ?.filter(
                    (flightData) =>
                      isOnlyRid ||
                      flightData?.reference?.tag?.toLowerCase() !== "rid"
                  )
                  .map((flightData) => (
                    <OperationPanelItem
                      key={flightData.reference.id}
                      flightData={flightData}
                      platformName={getPlatformName(
                        flightData.request.platform_uuid[0]
                      )}
                      trackerName={getTrackerName(
                        flightData.request.tracker_uuid[0]
                      )}
                      pilotName={getPilotName(flightData.request.pilot_uuid[0])}
                      onClick={() => showOperationDetail(flightData)}
                      isRemoteId={remoteIdCheck()}
                      // // as non expanded card does not display telemetry data may not need to pass it here
                      // telemetryData={telemetryData}
                      // handleRemoveOperationClick={handleRemoveOperationClick}
                      // onUpdateOperationState={onUpdateOperationState}
                      // handleVisabilityClick={handleVisabilityClick}
                      // role={role}
                      // getUserOperations={getUserOperations}
                      // getSelectedOperation={getSelectedOperation}
                      // handleOpenFlightAuthorizationForced={
                      //   handleOpenFlightAuthorizationForced
                      // }
                      // platformList={platformList}
                      // trackerList={trackerList}
                      // pilotList={pilotList}
                    />
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
