import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserAccess } from "@/store/actions";
import UserAccess from "@/model/UserAccess";
import Pilot from "@/model/api/Pilot";
import { User } from "@/model/api/User";

import { RiMenu2Line } from "react-icons/ri";
import { IoMoon } from "react-icons/io5";
import { Auth } from "aws-amplify";
import useWebsocket from "@/hooks/useWebsocket";
import { channels } from "@/config/channels";
import { messageTypes } from "@/config/messageTypes";
import UserInformation from "@/model/UseInformation";
import useCognitoAuth from "@/hooks/useCognitoAuth";
import LatLng from "@/model/LatLng";
import useColorMode from "@/hooks/useColorMode";
import BellNotificationType from "@/model/api/BellNotification";
import {
  IoMdNotificationsOutline,
  IoMdNotifications,
  IoMdCopy,
  IoIosAlert,
} from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import UserDropdown from "../UserDropdown";
import PlaceAutoComplete from "../Common/PlaceAutocomplete";
import NotificationDropdown from "../NotificationDropdown";
import OwnOrganisationManagement from "../MainMenu/OwnOrganisationManagement";
import PilotForm from "../MainMenu/AssetManagement/PilotForm";
import ProposeOperationDialog from "../Dialog/ProposeOperationDialog";

interface UserAccessState {
  userAccess: UserAccess;
}

type UserRoles =
  | "administrator"
  | "airspace_manager"
  | "flight_manager"
  | "pilot";

interface DashboardHeaderProps {
  api: any;
  isAuthority?: boolean;
  onPlaceSelected: (coordinate: LatLng) => void;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  onThemeChange: (newTheme: string) => void;
  onMenuSelected(menu: string, isOpening: boolean): void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
  isDJIDevice: boolean;
}

function toProperCase(str: string) {
  return str
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const hexToColorMap = {
  GREEN: "#95f985",
  YELLOW: "#FFF300",
  RYELLOW: "#ffff01",
  ORANGE: "#ffa500",
  RED: "#AA4A44",
  BLUE: "#9FC5EC",
  GREY: "#dddddd",
};

const getSeverityColor = (severity: string) => {
  if (severity === "Information") {
    return hexToColorMap.BLUE;
  }
  if (severity === "Informational") {
    return hexToColorMap.BLUE;
  }
  if (severity === "Error") {
    return hexToColorMap.YELLOW;
  }
  if (severity === "Important") {
    return hexToColorMap.RYELLOW;
  }
  if (severity === "Warning") {
    return hexToColorMap.ORANGE;
  }
  if (severity === "Emergency") {
    return hexToColorMap.RED;
  }
  // return if none of the severities match, should not occur
  return "#dddddd";
};

const getSeverityLevel = (severity: string) => {
  if (severity === "Information") {
    return 1;
  }
  if (severity === "Informational") {
    return 1;
  }
  if (severity === "Error") {
    return 2;
  }
  if (severity === "Important") {
    return 3;
  }
  if (severity === "Warning") {
    return 4;
  }
  if (severity === "Emergency") {
    return 5;
  }
  // return if none of the severities match, should not occur
  return 0;
};

export default function DashboardHeader({
  api,
  isAuthority,
  onPlaceSelected,
  showMessage,
  onConfirmation,
  onThemeChange,
  onMenuSelected,
  onOpenModal,
  onCloseModal,
  isDJIDevice,
}: DashboardHeaderProps) {
  const { channel } = useWebsocket({ channel: channels.NOTIFICATION_CHANNEL });
  const { logout } = useCognitoAuth();
  const [colorMode, setColorMode] = useColorMode();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [userCurrentRole, setUserCurrentRole] = useState<UserRoles>("pilot");
  const [userInformation, setUserInformation] = useState<UserInformation>({
    username: "heron",
    name: "Heron",
    email: "-",
    avatar: "/default_avatar.png",
  });
  const [
    userOrganisationAccountInformation,
    setUserOrganisationAccountInformation,
  ] = useState<User>();
  const [userPilotInformation, setUserPilotInformation] = useState<Pilot>();
  /* Bell Notifications */
  // const [newBellNotificationSeverity, setNewBellNotificationSeverity] =
  //   useState<string>("");
  const newBellNotificationSeverity = useRef<string>("");
  // const [bellNotifications, setBellNotifications] = useState<
  //   BellNotificationType[]
  // >([]);
  const bellNotifications = useRef<BellNotificationType[]>([]);
  const userAccess = useSelector((state: UserAccessState) => state.userAccess);
  const dispatch = useDispatch();

  const handleCopytoClipboard = (value?: string) => {
    if (!value) return;
    navigator.clipboard.writeText(value);
    showMessage && showMessage("Copied to clipboard");
  };

  const handleMenuClick = async (menu: string) => {
    setIsDropdownOpen(false);

    if (menu === "user-profile") {
      onOpenModal(
        "User Profile",
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h5 className="ml-4 font-medium">
            User UUID:{" "}
            <span className="text-blue-500 text-sm inline-flex items-center">
              {userOrganisationAccountInformation?.user_uuid}
              <IoMdCopy
                onClick={() => {
                  handleCopytoClipboard(
                    userOrganisationAccountInformation?.user_uuid
                  );
                }}
                size={14}
                className="ml-2 cursor-pointer"
              />
            </span>
          </h5>
          <PilotForm
            api={api}
            pilot={userPilotInformation}
            showMessage={showMessage}
            hasWritePrivilege={true}
          />
        </div>
      );
    }

    if (onConfirmation && menu === "signout") {
      onConfirmation(
        "Sign Out",
        "Are you sure you want to sign out?",
        (result) => {
          if (result) {
            logout();
          }
        }
      );
    }
  };

  const toggleUserDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsNotificationOpen(false);
  };

  const toggleNotificationDropdown = () => {
    setIsNotificationOpen(!isNotificationOpen);
    setIsDropdownOpen(false);
  };

  const toggleColorMode = () => {
    if (typeof setColorMode === "function") {
      const newColor = colorMode === "light" ? "dark" : "light";
      setColorMode(newColor);
      onThemeChange(newColor);
    }
  };

  const updateUserAccess = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userRoles = await api.getUser(user.username);
      const userPrivileges = await api.getUserPrivilege(user.username);

      const newUserAccess = {
        ...userAccess,
      };
      newUserAccess.role = userRoles?.data?.current_user_role;
      newUserAccess.user_roles = userRoles?.data?.user_roles;
      newUserAccess.privileges = userPrivileges?.data?.privileges;
      dispatch(setUserAccess(newUserAccess));
      setUserCurrentRole(newUserAccess.role);
    } catch (e) {
      console.log("Unable to obtain user data : ", e);
    }
  };
  useEffect(() => {
    if (!api) return;
    updateUserAccess();
  }, [api]);

  useEffect(() => {
    setUserCurrentRole(userAccess.role);
  }, [userAccess]);

  useEffect(() => {
    if (!api) return;
    const getUserAttributes = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const { attributes } = user;

        setUserInformation({
          username: user.username,
          name: user.name,
          email: attributes.email,
          avatar: "/default_avatar.png",
        });

        const userOrganisationAccountData = await api.getUser(user.username);
        setUserOrganisationAccountInformation(userOrganisationAccountData.data);
        return attributes;
      } catch (error) {
        console.error("Error fetching user attributes:", error);
        return null;
      }
    };

    getUserAttributes();
  }, [api]);

  /* Bell Notifications */

  const fetchLogMessages = async (lastBellNotificationMessage?: object) => {
    try {
      if (!lastBellNotificationMessage) {
        const res = await api?.getLogMessages();
        bellNotifications.current = res?.data?.items || [];
      } else {
        {
          const res = await api?.getLogMessages(lastBellNotificationMessage);
          if (!res?.data?.items) return;
          const currentBellNotificationMessages = [
            ...bellNotifications.current,
            ...res.data.items,
          ];
          bellNotifications.current = currentBellNotificationMessages;
          // if (!isAuthority) {
          //   setBellNotifications([
          //     ...currentBellNotificationMessages,
          //     res?.data,
          //   ]);
          // } else {
          //   setBellNotifications([
          //     ...currentBellNotificationMessages,
          //     res?.data?.items,
          //   ]);
          // }
        }
      }
    } catch (error) {
      console.log("error fetching log messages: ", error);
    }
  };

  const handleGetOlderBellNotificationsClick = () => {
    const data = {
      lastEvaluatedKey: {
        PK: bellNotifications.current[bellNotifications.current.length - 1].PK,
        SK: bellNotifications.current[bellNotifications.current.length - 1].SK,
      },
    };
    fetchLogMessages(data);
  };

  useEffect(() => {
    if (!api) return;

    let refreshTimeout: NodeJS.Timeout;
    const fetchNotificationTimer = async () => {
      try {
        await fetchLogMessages();
      } catch (err) {
        console.log(err);
      }
      // Log getLogMessages called every 30mins to ensure messages still in sync
      // New log messages are sent via websocket and added to notifications useState
      refreshTimeout = setTimeout(fetchNotificationTimer, 1000 * 60 * 30);
    };
    fetchNotificationTimer();
    return () => {
      clearTimeout(refreshTimeout);
    };
  }, []);

  const handleWSLogMessage = (
    singleBellNotificationWsMessage: BellNotificationType
  ) => {
    if (!singleBellNotificationWsMessage) return;
    handleNewNotificationAlert(singleBellNotificationWsMessage);
    const currentBellNotificationMessages = [
      singleBellNotificationWsMessage,
      ...bellNotifications.current,
    ];
    bellNotifications.current = currentBellNotificationMessages;
  };

  // Listener for WS Log Messages
  useEffect(() => {
    const removeLogMessageListener = channel?.addMessageListener(
      messageTypes.LOG_MESSAGE,
      handleWSLogMessage
    );
    return () => {
      removeLogMessageListener?.();
    };
  }, [channel, userAccess]);

  const getPilotDetails = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const { username } = user;
    const response = await api?.getPilots();
    const pilot = response.data.find(
      (singlePilot: any) => singlePilot.pilot_username === username
    );
    setUserPilotInformation(pilot);
  };

  useEffect(() => {
    if (!api) return;
    getPilotDetails();
  }, [api, Auth, isDropdownOpen]);

  const handleEmergencyMessage = (
    singleBellNotification: BellNotificationType
  ) => {
    const { message } = singleBellNotification.data;
    const messageSecondLine = "Please Land If Safe To Do So";
    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <IoIosAlert style={{ fontSize: "38px", color: "#FF0000" }} />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900">Alert</p>
                <p className="mt-1 text-sm text-gray-500">{message}</p>
                {messageSecondLine && (
                  <p className="mt-1 text-sm text-gray-500">
                    {messageSecondLine}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Acknowledge
            </button>
          </div>
        </div>
      ),
      {
        duration: Infinity,
      }
    );
  };

  // Handle Notification Icon Color when new alert
  const handleNewNotificationAlert = (
    singleBellNotificationWsMessage: BellNotificationType
  ) => {
    const rowInfoSplit = singleBellNotificationWsMessage.SK.split("#");
    const severity = rowInfoSplit[5];
    const currentMsgSeverityLevel = getSeverityLevel(
      newBellNotificationSeverity.current
    );
    if (severity === "Emergency") {
      handleEmergencyMessage(singleBellNotificationWsMessage);
    }
    if (getSeverityLevel(severity) > currentMsgSeverityLevel) {
      newBellNotificationSeverity.current = severity;
    }
  };

  useEffect(() => {
    if (!isNotificationOpen) return;
    newBellNotificationSeverity.current = "";
  }, [isNotificationOpen]);
  // Handle Notification Icon Color when new alert END

  const handleProposeOperation = async (operationID: string) => {
    try {
      if (!["pilot", "flight_manager"].includes(userAccess.role)) {
        showMessage &&
          showMessage("Pilot or flight manager role required", false, true);
        return;
      }
      if (!userAccess.privileges.includes("operations.read")) {
        showMessage &&
          showMessage("Read operation access required", false, true);
        return;
      }
      const content = (
        <ProposeOperationDialog
          operationUuid={operationID}
          onMenuSelected={onMenuSelected}
          showMessage={showMessage}
          onCloseModal={onCloseModal}
        />
      );

      onOpenModal("Proposed Operation", content, false, true);
    } catch (err) {
      showMessage && showMessage("Operation has been rejected or has expired ");
    }
  };

  return (
    <div id="dashboardHeader" className="dashboard-header relative">
      {/* Button Toggle Menu */}
      {/* <button className="rounded-container btn-icon">
        <RiMenu2Line
          className="fill-primary-300 dark:fill-white m-auto"
          size={24}
        />
      </button> */}
      <img
        src={colorMode === "light" ? "/HeronLogo.png" : "/HeronLogoWhite.png"}
        alt="logo"
        width={100}
        height={30}
        className="ml-6 object-contain w-28 h-fit"
      />
      <span className="grow" />
      {/* SearhBar */}
      <div
        className={`${
          isDJIDevice ? "w-[100px]" : "w-[400px]"
        } absolute left-1/2 transform -translate-x-1/2 z-[60]`}
      >
        <PlaceAutoComplete
          onSelectPlace={onPlaceSelected}
          showMessage={showMessage}
        />
      </div>

      {/* User Information */}
      <div className="relative">
        <div className="flex">
          <div
            className="flex ml-4 cursor-pointer"
            onClick={toggleUserDropdown}
          >
            <div className="flex flex-col justify-end items-end">
              <span className="label-blue font-semibold ml-2 text-lg">
                {userInformation.username}
              </span>
              <span className="label-secondary ml-2 text-sm">
                {userCurrentRole && toProperCase(userCurrentRole)}
              </span>
            </div>
            <img
              src="/default_avatar.png"
              alt="profile"
              width={30}
              height={30}
              className="rounded-full w-12 h-12 object-contain border-4 border-gray-400/30 ml-2"
            />
          </div>
          <button
            className="rounded-container btn-icon ml-4"
            onClick={toggleColorMode}
          >
            <IoMoon className="icon-btn" size={24} />
          </button>
          <button
            className="rounded-container btn-icon ml-4"
            onClick={toggleNotificationDropdown}
          >
            {!newBellNotificationSeverity.current ? (
              <IoMdNotificationsOutline className="icon-btn" size={26} />
            ) : (
              <IoMdNotifications
                size={26}
                style={{
                  color: getSeverityColor(newBellNotificationSeverity.current),
                }}
              />
            )}
          </button>
        </div>

        {/* User Dropdown */}
        {isDropdownOpen && (
          <UserDropdown
            userInformation={userInformation}
            className="absolute z-[999]"
            onMenuClicked={handleMenuClick}
            showMessage={showMessage}
            onOrganisationManagementMenuSelected={() =>
              onOpenModal(
                "Organisation Details",
                <OwnOrganisationManagement showMessage={showMessage} />
              )
            }
            isDJIDevice={isDJIDevice}
          />
        )}

        {/* Notification Dropdown */}
        {isNotificationOpen && (
          <NotificationDropdown
            bellNotifications={bellNotifications.current}
            handleGetOlderBellNotificationsClick={
              handleGetOlderBellNotificationsClick
            }
            handleProposeOperation={handleProposeOperation}
            className="absolute z-[999]"
          />
        )}
      </div>
    </div>
  );
}
