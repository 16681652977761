import { OperationJson } from "@/model/api/Operation";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import UserAccess from "@/model/UserAccess";
import PuckServer from "@/services/puckserver";
import moment from "moment";
import { FaPlaneDeparture, FaPlaneArrival } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import { IoIosArrowBack, IoMdCompass, IoMdPerson } from "react-icons/io";
import {
  MdHeight,
  MdMultilineChart,
  MdOutlineSettingsRemote,
  MdOutlineSpeed,
} from "react-icons/md";
import Button from "@/components/v1/Common/Button";
import { PiDrone } from "react-icons/pi";

interface UserAccessState {
  userAccess: UserAccess;
}

// this interface is unique to operation panel detail, do not use for mapbox telemetry
interface Telemetry {
  trackerSn: string;
  trackerImei: string;
  timestamp: number;
  puckResponses: (null | any)[][];
  gufi?: string;
}

interface TelemetryObject {
  [key: string]: Telemetry;
}

interface CustomTakeoffHeightObject {
  [key: string]: number;
}

interface OperationPanelDetailProps {
  api: any;
  operation?: OperationJson | null;
  getTrackerName: (uuid?: string) => string;
  getPlatformName: (uuid?: string) => string;
  getPilotName: (uuid?: string) => string;
  onReturn: () => void;
  onFocus: (clearFocus?: boolean) => void;
  onFocusRemoteId: (lat: number, lng: number) => void;
  onTakeOff: (operation: OperationJson) => void;
  onLiveStream: (pilot_uuid: string) => void;
  onMavlink: () => void;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onLandOperation: () => Promise<boolean | undefined>;
  onCancel: () => void;
  onContingentClick: () => void;
  onDownloadClick: () => void;
  isTakeOffLoading: boolean;
  isLandedLoading: boolean;
  isCancelLoading: boolean;
  isContingentLoading: boolean;
  isRemoteId?: boolean;
  isAuthority?: boolean;
  telemetryData?: TelemetryObject;
  customTakeoffHeight?: object;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
}

type AuthNotificationType = "hold" | "land" | "notify";

export default function OperationPanelDetail({
  api,
  operation,
  getTrackerName,
  getPlatformName,
  getPilotName,
  onReturn,
  onFocus,
  onFocusRemoteId,
  onTakeOff,
  onLiveStream,
  onMavlink,
  showMessage,
  onLandOperation,
  onCancel,
  onContingentClick,
  onDownloadClick,
  isTakeOffLoading,
  isLandedLoading,
  isCancelLoading,
  isContingentLoading,
  isRemoteId,
  isAuthority,
  telemetryData,
  onOpenModal,
  onCloseModal,
}: OperationPanelDetailProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [devEnv, setDevEnv] = useState(false);
  const [customTakeoffHeight, setCustomTakeoffHeight] =
    useState<CustomTakeoffHeightObject>({});

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);
  const envVar = useSelector((state: any) => state.envVar);

  useEffect(() => {
    // hide mavlink in prod till ready
    const isDevEnv =
      envVar["base_url-sp"].Value !== "https://sp.heronairbridge.com";
    setDevEnv(isDevEnv);
  }, [envVar]);

  const handleTakeoff = () => {
    if (operation?.details.state !== "Accepted") {
      showMessage?.(`Cannot takeoff an ${operation?.details.state} operation.`);
      return;
    }
    onTakeOff(operation);
  };

  const handleLiveStream = () => {
    if (!operation?.request.pilot_uuid[0]) return showMessage?.("No Pilot");
    onLiveStream(operation?.request.pilot_uuid[0]);
  };
  const handleMavlink = () => {
    if (operation?.details.state !== "Accepted") {
      showMessage?.(`Cannot start Mavlink.`);
      return;
    }
    onMavlink();
  };

  const handleOnFocus = () => {
    if (operation?.request.tag !== "rid") {
      if (!isFocused) {
        onFocus();
        setIsFocused(true);
      } else {
        onFocus(true);
        setIsFocused(false);
      }
    }
    if (operation?.request.tag === "rid") {
      if (
        operation &&
        telemetryData &&
        telemetryData[operation.details?.platform_tracker_pairs[0]?.tracker_sn]
          ?.puckResponses[0][1]?.position?.lat &&
        telemetryData[operation.details?.platform_tracker_pairs[0]?.tracker_sn]
          ?.puckResponses[0][1]?.position?.lng
      ) {
        onFocusRemoteId(
          telemetryData[
            operation.details?.platform_tracker_pairs[0]?.tracker_sn
          ]?.puckResponses[0][1]?.position?.lat,
          telemetryData[
            operation.details?.platform_tracker_pairs[0]?.tracker_sn
          ]?.puckResponses[0][1]?.position?.lng
        );
      } else {
        showMessage?.(
          `Unable to locate tracker ${
            operation?.details?.platform_tracker_pairs[0]?.tracker_name || ""
          }`,
          false,
          true
        );
      }
    }
  };

  const handleOnLandOperation = async () => {
    if (operation?.request.tag === "rid" && operation) {
      await PuckServer.unpairUserTracker(
        operation.details?.platform_tracker_pairs[0]?.tracker_sn
      );
    }
    const landResponse = await onLandOperation();
    if (landResponse) {
      onReturn();
    }
  };

  const handleCustomTakeoffHeightClick = (trackerSn?: string) => {
    if (!trackerSn) return;
    setCustomTakeoffHeight({ ...customTakeoffHeight, [trackerSn]: 0 });
    const content = (
      <div className="w-full mr-2 mb-5">
        <label htmlFor="intent" className="label">
          Enter Custom Takeoff Height
        </label>
        <input
          type="text"
          name="intent"
          className="input-text mb-5"
          onChange={(e) =>
            setCustomTakeoffHeight({
              ...customTakeoffHeight,
              [trackerSn]: parseInt(e.target.value, 10) || 0,
            })
          }
        />
        <Button
          className="btn-rounded-primary"
          size="small"
          onClick={() => onCloseModal()}
          text="Confirm"
        />
      </div>
    );

    onOpenModal("Custom takeoff height", content, false, true);
  };

  const getTrackerTelemetryDetails = (trackerSn?: string) => {
    if (!trackerSn || !telemetryData || !telemetryData[trackerSn]) {
      return (
        <div>
          <div className="flex p-2 rounded-lg border-2 mb-2 dark:text-white dark:border-gray-600">
            <div className="flex text-sm grow items-center">
              <MdOutlineSpeed size={16} color="gray" />
              &nbsp;
              <span>- m/s</span>
            </div>
            <div className="flex text-sm grow items-center">
              <MdHeight size={16} color="gray" />
              &nbsp;
              <span>- m</span>
            </div>
            <div className="flex text-sm grow items-center">
              <IoMdCompass size={16} color="gray" />
              &nbsp;
              <span>- deg</span>
            </div>
          </div>
          <div className="flex p-2 rounded-lg border-2 mb-2 dark:text-white dark:border-gray-600">
            <div className="flex text-sm grow items-center">
              <MdHeight size={16} color="gray" />
              &nbsp;
              <span>{" - "}m</span>
            </div>
            <div className="flex text-sm grow items-center">
              <button onClick={() => handleCustomTakeoffHeightClick(trackerSn)}>
                {trackerSn && customTakeoffHeight[trackerSn] ? (
                  <span className="text-primary-600 text-sm font-medium mr-2">
                    Custom : {customTakeoffHeight[trackerSn]}m
                  </span>
                ) : (
                  <span className="text-primary-600 text-sm font-medium mr-2">
                    REF : -
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      );
    }
    const handleReferenceHeight = () => {
      const positionAlt =
        telemetryData[trackerSn]?.puckResponses[0][1]?.position?.alt;
      const distance =
        telemetryData[trackerSn]?.puckResponses[0][1]?.height?.distance;

      if (customTakeoffHeight[trackerSn] && Number.isFinite(positionAlt)) {
        return (
          <span>
            {(positionAlt - customTakeoffHeight[trackerSn]).toFixed(1)} m
          </span>
        );
      }

      return (
        <span>{Number.isFinite(distance) ? distance.toFixed(1) : " - "} m</span>
      );
    };

    return (
      <div>
        <div className="flex p-2 rounded-lg border-2 dark:text-white dark:border-gray-600">
          <div className="flex text-sm grow items-center">
            <MdOutlineSpeed size={16} color="gray" />
            &nbsp;
            <span>
              {telemetryData[trackerSn]?.puckResponses[0][1]?.speed || " - "}
              m/s
            </span>
          </div>
          <div className="flex text-sm grow items-center">
            GPS
            <MdHeight size={16} color="gray" />
            &nbsp;
            <span>
              {telemetryData[
                trackerSn
              ]?.puckResponses[0][1]?.position?.alt.toFixed(1) || " - "}
              m
            </span>
          </div>
          <div className="flex text-sm grow items-center">
            <IoMdCompass size={16} color="gray" />
            &nbsp;
            <span>
              {telemetryData[trackerSn]?.puckResponses[0][1]?.track || " - "}
              deg
            </span>
          </div>
        </div>
        <div className="flex p-2 rounded-lg border-2 mb-2 dark:text-white dark:border-gray-600">
          <div className="flex text-sm grow items-center">
            <MdHeight size={16} color="gray" />
            &nbsp;
            <span>{handleReferenceHeight()}</span>
          </div>
          <div className="flex text-sm grow items-center">
            <button onClick={() => handleCustomTakeoffHeightClick(trackerSn)}>
              {customTakeoffHeight[trackerSn] ? (
                <span className="text-primary-600 text-sm font-medium mr-2">
                  Custom : {customTakeoffHeight[trackerSn]}m
                </span>
              ) : (
                <span className="text-primary-600 text-sm font-medium mr-2">
                  REF :
                  {telemetryData[trackerSn]?.puckResponses[0][1]?.height
                    ?.reference || " - "}
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  /* Auth Notification */

  const sendNotification = async (message: string) => {
    const operation_uuid = operation?.reference?.id;
    if (!operation_uuid) {
      showMessage?.("Error sending notification", false, true);
      return;
    }
    try {
      await api.aas.sendOperationNotification({
        operation_uuid,
        message,
      });
      showMessage?.("Notification Sent", true);
    } catch (error) {
      showMessage?.("Error sending notification.", false, true);
    } finally {
      onCloseModal();
    }
  };

  const handleAuthNotification = (type: AuthNotificationType) => {
    let message: string;
    let notificationMessage: string;
    const handleInputChange = (e: any) => {
      notificationMessage = e.target.value;
    };
    if (type === "hold") {
      message = "Requesting this operation to HOLD. Proceed?";
      notificationMessage = `You have been notified to HOLD flight operation: ${operation?.request?.description}`;
    } else if (type === "notify") {
      message = "Submit a notification to this operation:";
      notificationMessage = ``;
    } else {
      message = "Requesting this operation to LAND. Proceed?";
      notificationMessage = `You have been notified to LAND flight operation: ${operation?.request?.description}`;
    }
    const content = (
      <div className="w-full flex flex-col">
        <div className="ml-3 flex-1 w-full">
          <p className="text-medium font-medium text-gray-900">Caution</p>
          <p
            className={`mt-1 text-sm text-gray-500 ${
              type !== "notify" && "min-h-[200px]"
            }`}
          >
            {message}
          </p>
          {type === "notify" && (
            <textarea
              onChange={handleInputChange}
              className="mt-2 p-2 border border-gray-300 rounded w-[90%] h-[200px]"
              placeholder="Type your message"
              rows={4}
            />
          )}
        </div>
        <div className="flex">
          <button
            onClick={() => sendNotification(notificationMessage)}
            className="w-full border border-gray-200 rounded-none rounded-l-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Send
          </button>
          <button
            onClick={() => onCloseModal()}
            className="w-full border border-gray-200 rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-red-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
        </div>
      </div>
    );
    onOpenModal("Notification", content, false, true);
  };

  return (
    <div className="flex-col h-[100%] overflow-auto px-2">
      {/* Navigation & Form Title */}
      <div className="flex items-center content-center mb-2 py-2">
        <button onClick={onReturn}>
          <IoIosArrowBack size={22} className="m-auto dark:fill-white" />
        </button>
        <h5 className="font-medium ml-2 mr-1 grow dark:text-white">
          {operation?.reference.description}
        </h5>
        <div className="flex text-sm items-center dark:text-white">
          <MdMultilineChart size={16} color="gray" />
          &nbsp;
          {operation?.request.operation_type.toUpperCase()}
        </div>
      </div>

      {/* Operation Details */}
      <label className="label">Operation Details</label>
      {operation?.request.platform_uuid.map((platform_uuid, index) => {
        return (
          <div key={platform_uuid}>
            <div className="flex-col rounded-container">
              <div className="flex mb-1 items-center">
                <MdOutlineSettingsRemote size={14} />
                <p className="ml-2">
                  {getTrackerName(operation?.request.tracker_uuid[index])}
                </p>
              </div>
              {operation?.request.tag !== "rid" && (
                <div className="flex mb-1 items-center">
                  <PiDrone size={14} />
                  <p className="ml-2">
                    {getPlatformName(operation?.request.platform_uuid[index])}
                  </p>
                </div>
              )}

              {operation?.request.tag !== "rid" && (
                <div className="flex mb-1 items-center">
                  <IoMdPerson size={14} />
                  <p className="ml-2">
                    {getPilotName(operation?.request.pilot_uuid[index])}
                  </p>
                </div>
              )}
            </div>
            {getTrackerTelemetryDetails(
              operation?.details.platform_tracker_pairs[index].tracker_sn
            )}
          </div>
        );
      })}
      <label className="label">Operation Timing</label>
      <div className="flex-col mb-2 rounded-container">
        <div className="flex mb-1 items-center">
          <FaPlaneDeparture size={14} />
          <p className="ml-2">
            Depart :{" "}
            {moment(operation?.request.time_start).format("YYYY-MM-DD HH:mm A")}
          </p>
        </div>
        <div className="flex mb-1 items-center">
          <FaPlaneArrival size={14} />
          <p className="ml-2">
            Arrive :{" "}
            {moment(operation?.request.time_end).format("YYYY-MM-DD HH:mm A")}
          </p>
        </div>
      </div>
      <label className="label">Operation Actions</label>
      <div className="grid grid-cols-3 gap-3">
        {operation?.request.tag === "rid" && (
          <Button
            className={
              isFocused ? "btn-rounded-focused" : "btn-rounded-primary"
            }
            size="small"
            onClick={() => handleOnFocus()}
            text="Focus"
          />
        )}
        {operation?.request.tag === "rid" && (
          <Button
            className={
              operation?.request.tag === "rid"
                ? "btn-rounded-danger"
                : "btn-rounded-primary"
            }
            size="small"
            onClick={handleOnLandOperation}
            isLoading={isLandedLoading}
            text={operation?.request.tag === "rid" ? "Stop" : "Landed"}
          />
        )}
      </div>
      {["pilot", "flight_manager"].includes(userAccess.role) &&
        operation?.request.tag !== "rid" && (
          <div className="grid grid-cols-3 gap-3">
            {userAccess.role === "pilot" && (
              <Button
                className="btn-rounded-primary"
                size="small"
                onClick={handleTakeoff}
                isLoading={isTakeOffLoading}
                text="Take Off"
              />
            )}
            <Button
              className="btn-rounded-primary"
              size="small"
              onClick={handleOnLandOperation}
              isLoading={isLandedLoading}
              text="Landed"
            />
            <Button
              className={
                isFocused ? "btn-rounded-focused" : "btn-rounded-primary"
              }
              size="small"
              onClick={() => handleOnFocus()}
              text="Focus"
            />
            <Button
              className="btn-rounded-secondary dark:bg-white"
              size="small"
              type="light"
              onClick={onDownloadClick}
              text="Download"
            />
            <Button
              className="btn-rounded-secondary dark:bg-white"
              size="small"
              type="light"
              onClick={onCancel}
              isLoading={isCancelLoading}
              text="Cancel"
            />
            <Button
              className="btn-rounded-danger"
              size="small"
              onClick={onContingentClick}
              isLoading={isContingentLoading}
              text="Contingent"
            />
            <Button
              className="btn-rounded-secondary dark:bg-white"
              size="small"
              type="light"
              onClick={handleLiveStream}
              text="Livestream"
            />

            {/* hide mavlink in prod till ready */}
            {devEnv && (
              <Button
                className="btn-rounded-secondary dark:bg-white"
                size="small"
                type="light"
                onClick={handleMavlink}
                text="Mavlink"
              />
            )}
          </div>
        )}
      {isAuthority && !isRemoteId && (
        <div className="grid grid-cols-3 gap-3">
          <Toaster />
          <Button
            className="btn-rounded-primary"
            size="small"
            onClick={() => handleAuthNotification("hold")}
            text="Hold"
          />
          <Button
            className="btn-rounded-primary"
            size="small"
            onClick={() => handleAuthNotification("land")}
            text="Land"
          />
          <Button
            className={
              isFocused ? "btn-rounded-focused" : "btn-rounded-primary"
            }
            size="small"
            onClick={() => handleOnFocus()}
            text="Focus"
          />
          <Button
            className="btn-rounded-secondary dark:bg-white"
            size="small"
            type="light"
            onClick={() => handleAuthNotification("notify")}
            text="Notify"
          />
          <Button
            className="btn-rounded-danger"
            size="small"
            onClick={onContingentClick}
            isLoading={isContingentLoading}
            text="Contingent"
          />
          <Button
            className="btn-rounded-secondary dark:bg-white"
            size="small"
            type="light"
            onClick={handleLiveStream}
            text="Livestream"
          />
          {/* hide mavlink in prod till ready */}
          {devEnv && (
            <Button
              className="btn-rounded-secondary dark:bg-white"
              size="small"
              type="light"
              onClick={handleMavlink}
              text="Mavlink"
            />
          )}
        </div>
      )}
    </div>
  );
}
