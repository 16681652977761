import React, { useEffect, useRef, useState } from "react";
import { useApi } from "@/api/useApi";
import {
  OperationTelemetry,
  OperationLogState,
} from "@/model/api/OperationTelemetry";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { ModelLayer } from "@/components/MapB/model-layer";
import {
  load3dModels,
  addTerrainSource,
  addNoFlyLayers,
  addNoFlySources,
  addBuildingLayer,
} from "@/components/MapB/layers";

interface ReplayData {
  [timestamp: string]: OperationTelemetry[];
}

interface MapWithSliderProps {
  operationUuid: string;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onCloseModal: () => void;
}

mapboxgl.accessToken = process.env.REACT_APP_MAPB_TOKEN || "";

/**
 * Converts angle from degrees to radians
 * @param {Number} degrees Angle in degrees
 * @returns Angle in radians
 */
function degToRad(degrees: number) {
  const pi = Math.PI;
  return degrees * (pi / 180);
}
const formatDate = (date: string) => {
  let d = new Date(date);

  if (isNaN(d.getTime())) {
    return false;
  }

  const timezoneoffset: number = d.getTimezoneOffset() * 60 * 1000 || 0;
  d = new Date(d.valueOf() - timezoneoffset);

  return [`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`];
};

function MapWithSlider(props: MapWithSliderProps) {
  const { operationUuid, showMessage, onCloseModal } = props;
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lng, setLng] = useState(103.8167107035549);
  const [lat, setLat] = useState(1.366701902383488);
  const [mapHeight, setMapHeight] = useState(
    document.documentElement.clientHeight - 300
  );
  const [modelMap, setModelMap] = useState(new Map());
  const [currentMapStyle, setCurrentMapStyle] = useState(
    "mapbox://styles/mapbox/dark-v11"
  );
  const [isLoading, setIsLoading] = useState(true);
  const [operationTelemetry, setOperationTelemetry] = useState<
    OperationTelemetry[]
  >([]);
  // list of timestamps based on telemetry recieved
  const [timestampIndex, setTimestampIndex] = useState<string[]>([]);
  // replay data
  const [replayDataObj, setReplayDataObj] = useState<ReplayData>({});
  const [isTrackerPopupEnabled, setIsTrackerPopupEnabled] = useState(false);

  const api = useApi();

  const popupRefs = useRef<mapboxgl.Popup[]>([]);
  const processTelemetry = (
    processTelemetry: OperationTelemetry[],
    operationLogState: OperationLogState[]
  ) => {
    const replayData: ReplayData = {};

    // If not sorted here, operation state loop acts unpredictably
    const sortedOperationLogState = [...operationLogState].sort(
      (a, b) => Number(a.event_time) - Number(b.event_time)
    );

    processTelemetry.forEach((singleTrackerTelemetry) => {
      singleTrackerTelemetry.telemetry.forEach((singleTimeStampTelemetry) => {
        const epochTime = new Date(
          singleTimeStampTelemetry.timestamp
        ).getTime();

        let operationState = "Activated";
        for (let i = sortedOperationLogState.length - 1; i >= 0; i -= 1) {
          if (Number(sortedOperationLogState[i].event_time) <= epochTime) {
            operationState = sortedOperationLogState[i].operation_state;
            break;
          }
        }

        const timestampNearestSecond =
          singleTimeStampTelemetry.timestamp.split(".")[0];
        if (!timestampNearestSecond) return;

        if (!replayData[timestampNearestSecond]) {
          replayData[timestampNearestSecond] = [];
        }
        replayData[timestampNearestSecond].push({
          tracker_sn: singleTrackerTelemetry.tracker_sn,
          telemetry: [singleTimeStampTelemetry],
          operationState,
        });
      });
    });

    setReplayDataObj(replayData);
  };

  const retrieveTelemetry = async (operationUuid: string) => {
    try {
      showMessage && showMessage("Retrieving telemetry for operation");
      const telemetryData = await api.getOperationTelemetry(operationUuid);
      const operationLogState = await api.getOperationLogState(operationUuid);

      // setOperationTelemetry(telemetryData.data);
      if (map.current)
        map.current.flyTo({
          center: [
            telemetryData.data[0].telemetry[0].position.lng,
            telemetryData.data[0].telemetry[0].position.lat,
          ],
          zoom: 15,
        });
      processTelemetry(telemetryData.data, operationLogState.data);
    } catch {
      showMessage && showMessage("No telemetry for operation", false, true);
    }
  };
  /** Load Telemetry Data */
  useEffect(() => {
    if (!operationUuid) return;
    retrieveTelemetry(operationUuid);
  }, [operationUuid]);
  /** Load Telemetry Data End */

  async function convertTrackerData(
    trackerData: OperationTelemetry[],
    modelM: any
  ) {
    const output = trackerData.flatMap((data) => {
      return data.telemetry.map(async (t: any) => {
        const positionLng = t?.position.lng ?? 0;
        const positionLat = t?.position.lat ?? 0;
        const color = data.operationState;
        let status;
        if (color === "Activated") {
          status = "ActivatedArrowModel";
        } else if (color === "Contingent") {
          status = "ContingentArrowModel";
        } else if (color === "Nonconforming") {
          status = "NonconformingArrowModel";
        } else if (color === "Ended") {
          status = "EndedArrowModel";
        }
        const model = modelM.get(status || "ActivatedArrowModel");
        // const model = modelM.get("ActivatedArrowModel");
        const track = t?.track;
        const alt = t?.position.alt;
        const time = t?.timestamp;
        const trackerSerial = t?.tracker_sn;
        const { operationState } = data;
        return {
          model,
          fixedSize: true,
          maxZoomScale: 0.15,
          scale: [0.0000013, 0.0000013, 0.0000013],
          rotate: [degToRad(90), degToRad(180 + track), 0],
          position: {
            lng: positionLng,
            lat: positionLat,
          },
          altitude: Number(alt),
          currentTime: formatDate(time),
          color,
          serialNumber: trackerSerial,
          operationState,
        };
      });
    });
    const outputData = await Promise.all(output);
    return outputData;
  }

  function addTrackerLayer(currMap: mapboxgl.Map) {
    if (currMap.getLayer("tracker-layer")) return;
    const trackerLayer = new ModelLayer("tracker-layer", convertTrackerData);
    currMap.addLayer(trackerLayer as unknown as mapboxgl.AnyLayer);
  }

  useEffect(() => {
    if (!map.current || !replayDataObj) return;
    setTimestampIndex(Object.keys(replayDataObj).sort());
    setMaxIndex(Object.keys(replayDataObj).length - 1);
    setIsLoading(false);
    setIsPlaying(true);
  }, [replayDataObj]);

  useEffect(() => {
    if (!map.current) return;
    const trackerLayer = map.current.getLayer("tracker-layer") as unknown as {
      implementation: { setData: any };
    };
    const replayData = replayDataObj[timestampIndex[currentIndex]];
    if (trackerLayer && replayData) {
      trackerLayer.implementation.setData(replayData, modelMap);
    }
  }, [modelMap, isPlaying, currentIndex, timestampIndex]);

  /** MAPB INIT */
  useEffect(() => {
    if (map.current || !mapContainer.current || !modelMap) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: currentMapStyle,
      center: [lng, lat],
      zoom: 11,
    });

    map.current.on("load", () => {
      if (!map.current) return;
      map.current.addControl(new mapboxgl.NavigationControl(), "top-right");

      load3dModels(modelMap);
      addTrackerLayer(map.current);
    });

    return () => {
      map.current?.remove();
      map.current = null;
    };
  }, [lng, lat, currentMapStyle, modelMap]);

  // Tracker Popup

  useEffect(() => {
    if (!isTrackerPopupEnabled) {
      popupRefs.current = [];
      return;
    }
    if (!map.current) return;
    try {
      // Remove old popups before adding new ones
      popupRefs.current.forEach((popup) => popup.remove());
      popupRefs.current = [];

      if (map.current?.getLayer("tracker-layer")) {
        const trackerLayerData: any = map.current?.getLayer("tracker-layer");
        trackerLayerData.implementation.data[0].forEach(
          (singleTracker: any) => {
            const { lng, lat, alt } = singleTracker.telemetry[0].position;
            const trackerSN = singleTracker.tracker_sn;
            const state = singleTracker.operationState;

            const popup = new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
              anchor: "top",
            })
              .setLngLat([lng, lat])
              .setHTML(
                `<div style="
                padding: 5px; 
                font-size: 12px; 
                font-family: Arial, sans-serif; 
                background-color: rgba(0, 0, 0, 0.8);
                color: white;
                border-radius: 5px;
                text-align: center;
              ">
                <strong>SN:</strong> ${trackerSN} <br>
                <strong>Status:</strong> ${state} <br>
                <strong>Alt:</strong> ${alt.toFixed(5)} <br>
                <strong>Lat:</strong> ${lat.toFixed(5)} <br>
                <strong>Lng:</strong> ${lng.toFixed(5)}
              </div>`
              )
              .addTo(map.current!);

            popupRefs.current.push(popup);
          }
        );
      }

      return () => {
        popupRefs.current.forEach((popup) => popup.remove());
        popupRefs.current = [];
      };
    } catch (e) {
      console.log(e);
    }
  }, [currentIndex, isTrackerPopupEnabled]);

  /** MAPB INIT END */

  useEffect(() => {
    if (!isPlaying) return;
    const interval = setInterval(() => {
      const newIndex = currentIndex + 1;
      if (newIndex <= Object.keys(timestampIndex).length)
        setCurrentIndex((s) => s + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [isPlaying]);

  return (
    <div className="relative w-full h-full">
      <button
        onClick={() => onCloseModal()}
        className="w-full h-8 text-sm font-medium bg-red-500 text-white rounded-md mb-5"
      >
        Close
      </button>
      <div ref={mapContainer} style={{ height: mapHeight, width: "100%" }} />
      <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 p-4 bg-white shadow-lg rounded-lg w-[300px]">
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-3">
            <input
              type="range"
              min={0}
              max={maxIndex}
              value={currentIndex}
              onChange={(e) => setCurrentIndex(Number(e.target.value))}
              className="flex-1 h-2 rounded-lg bg-gray-300"
            />
            <button
              onClick={() => setIsPlaying(!isPlaying)}
              className="w-16 h-8 text-sm font-medium bg-blue-500 text-white rounded-md"
            >
              {isPlaying ? "Pause" : "Play"}
            </button>
          </div>

          <div className="flex items-center justify-between gap-3">
            <div className="text-sm text-gray-700">
              {formatDate(timestampIndex[currentIndex])
                ? formatDate(timestampIndex[currentIndex])
                : "Processing Data"}
            </div>
            <div className="flex gap-3">
              <button
                onClick={() => setIsTrackerPopupEnabled(!isTrackerPopupEnabled)}
                className={`w-16 h-8 text-sm font-medium ${
                  isTrackerPopupEnabled ? "bg-blue-500" : "bg-gray-500"
                } text-white rounded-md`}
              >
                Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapWithSlider;
